import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import { useSigninLazyQuery } from '../../hooks';
import { getErrorText } from '../../utils';
import { loginValidationSchema } from '../../validation';

export const LoginForm = () => {
  const [getSignIn, { error: requestError }] = useSigninLazyQuery();

  const [showSubmitError, setShowSubmitError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    setShowSubmitError(true);
    await getSignIn({ variables: { input: values } });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor='email-login'>Indirizzo e-mail</InputLabel>
          <OutlinedInput
            id='email-login'
            placeholder='info@email.com'
            fullWidth
            autoComplete='email'
            onFocus={() => setShowSubmitError(false)}
            error={Boolean(errors.email)}
            {...register('email')}
          />
          {errors.email && (
            <FormHelperText error id='standard-weight-helper-text-email-login'>
              {errors.email.message.toString()}
            </FormHelperText>
          )}
        </Stack>
        <Stack spacing={1}>
          <InputLabel htmlFor='password-login'>Parola d&apos;ordine</InputLabel>
          <OutlinedInput
            fullWidth
            error={Boolean(errors.password)}
            id='-password-login'
            type={showPassword ? 'text' : 'password'}
            onFocus={() => setShowSubmitError(false)}
            autoComplete='current-password'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            placeholder='********'
            {...register('password')}
          />
          {errors.password && (
            <FormHelperText
              error
              id='standard-weight-helper-text-password-login'
            >
              {errors.password.message.toString()}
            </FormHelperText>
          )}
        </Stack>
        <Stack
          mt='-1'
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Link
            variant='body2'
            component={RouterLink}
            to={ROUTES.forgotPassword}
            color='text.primary'
          >
            Dimenticato la password?
          </Link>
        </Stack>
        {requestError && showSubmitError && (
          <FormHelperText style={{ textAlign: 'center' }} error>
            {getErrorText(requestError?.message)}
          </FormHelperText>
        )}
        <Stack>
          <Button
            disabled={isSubmitting}
            fullWidth
            size='large'
            type='submit'
            variant='contained'
          >
            Accedere
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
