import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { Box, Card, CardContent, Typography } from '@mui/material';
//eslint-disable-next-line
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { palette } from '../../theme/foundations';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DashboardDiagram = ({ company }) => {
  const plugins = [
    {
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        let fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'top';
        //eslint-disable-next-line
        let text = `${company?.dmUsedPercentage}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const data = {
    datasets: [
      {
        maintainAspectRatio: false,
        responsive: false,
        data: [
          `${company?.dmUsedPercentage}`,
          `${100 - company?.dmUsedPercentage}`,
        ],
        backgroundColor: ['#3a425b', '#ddddde'],
        borderColor: ['#3a425b', '#ddddde'],
        borderWidth: 1,
      },
    ],
  };

  const getUpOrDownArrow = (dmUsedPercentage, dmUsedCurrentYear) => {
    if (!dmUsedCurrentYear) return null;
    if (dmUsedPercentage > dmUsedCurrentYear) {
      return <ArrowDownwardIcon sx={{ color: 'red' }} />;
    } else if (dmUsedPercentage < dmUsedCurrentYear) {
      return <ArrowUpwardOutlinedIcon sx={{ color: 'green' }} />;
    } else {
      return null;
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography mb={3}>Utilizzo Deminimis</Typography>
        {company?.dmUsedPercentage ? (
          <>
            <Doughnut
              data={data}
              options={{
                cutout: '80%',
                rotation: 180,
              }}
              plugins={plugins}
            />
            <Box
              display='flex'
              justifyContent='space-around'
              alignItems='center'
              flexWrap='wrap'
              mt={4}
            >
              <Box>
                <Box display='flex' flexDirection='row' marginRight='10px'>
                  <Typography
                    align='center'
                    fontWeight={700}
                    color={palette.blue400}
                    marginRight='10px'
                  >
                    {'UTILLIZZATO'}
                  </Typography>
                  <Typography
                    width='45px'
                    height='25px'
                    borderRadius='3px'
                    backgroundColor={palette.blue400}
                    align='center'
                    fontWeight={700}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    fontSize='13px'
                    color={palette.text.secondary}
                  >{`${company?.dmUsedPercentage}%`}</Typography>
                </Box>
                <Box
                  display='flex'
                  alignItems='flex-end'
                  justifyContent='center'
                >
                  <Typography variant={'h1'} fontSize='28px' fontWeight={500}>
                    {company?.dmUsed}
                  </Typography>
                  <Typography fontSize='13px' marginLeft='5px'>
                    €
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box display='flex' flexDirection='row'>
                  <Typography
                    align='center'
                    fontWeight={700}
                    color={palette.blue300}
                    marginRight='10px'
                  >
                    {'RESIDUO'}
                  </Typography>
                  <Typography
                    width='45px'
                    height='25px'
                    borderRadius='3px'
                    backgroundColor={palette.blue300}
                    align='center'
                    fontWeight={700}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    fontSize='13px'
                    color={palette.text.secondary}
                  >
                    {`${100 - company?.dmUsedPercentage}%`}
                  </Typography>
                </Box>
                <Box
                  display='flex'
                  alignItems='flex-end'
                  justifyContent='center'
                >
                  <Typography variant={'h1'} fontSize='28px' fontWeight={500}>
                    {company?.dmYearN + company?.dmYearN1 + company?.dmYearN2}
                  </Typography>
                  <Typography fontSize='13px' marginLeft='5px'>
                    €
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Typography align='center'>Non ci sono dati</Typography>
        )}

        <Box
          display='flex'
          justifyContent='space-around'
          alignItems='center'
          mt={4}
        >
          <Box>
            <Typography align='center'>{company?.yearN}</Typography>
            <Box display='flex' justifyContent='center' alignItems='center'>
              {getUpOrDownArrow(company?.dmUsedPercentage, company?.dmYearN)}
              {company?.dmYearN}
            </Box>
          </Box>
          <Box>
            <Typography align='center'>{company?.yearN1}</Typography>
            <Box display='flex' justifyContent='center' alignItems='center'>
              {getUpOrDownArrow(company?.dmUsedPercentage, company?.dmYearN1)}
              {company?.dmYearN1}
            </Box>
          </Box>
          <Box>
            <Typography align='center'>{company?.yearN2}</Typography>
            <Box display='flex' justifyContent='center' alignItems='center'>
              {getUpOrDownArrow(company?.dmUsedPercentage, company?.dmYearN2)}
              {company?.dmYearN2}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

DashboardDiagram.propTypes = {
  company: PropTypes.shape({
    dmUsedPercentage: PropTypes.number,
    dmYearN: PropTypes.number,
    dmYearN1: PropTypes.number,
    dmYearN2: PropTypes.number,
    yearN: PropTypes.number,
    yearN1: PropTypes.number,
    yearN2: PropTypes.number,
    dmUsed: PropTypes.number,
  }),
};
