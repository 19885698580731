import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { RequestHandler } from '../../../components';
import { ROLES } from '../../../constants/constants';
import { useCreateUserMutation, useEditUserMutation } from '../../../hooks';
import { palette } from '../../../theme/foundations';
import { userValidationSchema } from '../../../validation';

export const UserInfoForm = ({ userData, companiesData }) => {
  const { id } = useParams();

  const [createUser, { loading: createUserLoading }] = useCreateUserMutation();
  const [editUserMutation, { loading: editUserLoading }] =
    useEditUserMutation();

  const companies = companiesData?.getCompaniesAsAdmin?.companies?.filter(
    (company) => company.client,
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(userValidationSchema),
    defaultValues: {
      firstName: userData?.getUserById?.firstName || '',
      lastName: userData?.getUserById?.lastName || '',
      email: userData?.getUserById?.email || '',
      role: userData?.getUserById?.role || '',
      cellPhone: userData?.getUserById?.cellPhone || '',
      landlinePhone: userData?.getUserById?.landlinePhone || '',
      companyId: userData?.getUserById?.companyId || '',
    },
  });

  const watchRole = watch('role');

  const compareEditInput = (data, input) =>
    Object.entries(data).reduce((acc, [key, value]) => {
      if (input[key] && input[key] !== value) {
        return { ...acc, [key]: input[key] };
      } else return { ...acc };
    }, {});

  const onSubmit = (input) => {
    createUser({
      variables: {
        input,
      },
    });
  };

  const onEdit = (input) => {
    const editInput = compareEditInput(userData?.getUserById, input);

    editUserMutation({
      variables: {
        id: Number(id),
        input: {
          ...editInput,
        },
      },
    });
  };

  return (
    <RequestHandler loading={createUserLoading || editUserLoading}>
      <Box
        component={'form'}
        onSubmit={handleSubmit(!id ? onSubmit : onEdit)}
        noValidate
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={3} xs={12} item>
                <Grid item xs={6}>
                  <InputLabel htmlFor='first-name'>Nome</InputLabel>
                  <OutlinedInput
                    id='first-name'
                    type='text'
                    placeholder='Nome'
                    fullWidth
                    autoComplete='first-name'
                    error={Boolean(errors.firstName)}
                    {...register('firstName')}
                  />
                  {errors.firstName && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-text-first-name'
                    >
                      {errors.firstName.message.toString()}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor='last-name'>Cognome</InputLabel>
                  <OutlinedInput
                    id='last-name'
                    type='text'
                    placeholder='Cognome'
                    fullWidth
                    autoComplete='last-name'
                    error={Boolean(errors.lastName)}
                    {...register('lastName')}
                  />
                  {errors.lastName && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-text-last-name'
                    >
                      {errors.lastName.message.toString()}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3} item xs={12}>
                <Grid item xs={6}>
                  <InputLabel htmlFor='email-login'>E-mail</InputLabel>
                  <OutlinedInput
                    id='email-login'
                    type='email'
                    placeholder='info@email.com'
                    fullWidth
                    autoComplete='email'
                    error={Boolean(errors.email)}
                    {...register('email')}
                  />
                  {errors.email && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-text-email-login'
                    >
                      {errors.email.message.toString()}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor='cell-phone'>
                    Numero di cellulare
                  </InputLabel>
                  <OutlinedInput
                    id='cell-phone'
                    type='text'
                    placeholder='+XXXXXXXXXXXX'
                    fullWidth
                    autoComplete='cellPhone'
                    error={Boolean(errors.cellPhone)}
                    {...register('cellPhone')}
                  />
                  {errors.cellPhone && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-text-cell-phone'
                    >
                      {errors.cellPhone.message.toString()}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3} item xs={12}>
                <Grid item xs={6}>
                  <InputLabel htmlFor='landline-phone'>
                    Numero di telefono fisso
                  </InputLabel>
                  <OutlinedInput
                    id='landline-phone'
                    type='text'
                    placeholder='+XXXXXXXXXXXX'
                    fullWidth
                    autoComplete='landlinePhone'
                    error={Boolean(errors.landlinePhone)}
                    {...register('landlinePhone')}
                  />
                  {errors.landlinePhone && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-text-landline-phone'
                    >
                      {errors.landlinePhone.message.toString()}
                    </FormHelperText>
                  )}
                </Grid>
                {!id && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        Seleziona un ruolo
                      </Typography>
                      <Controller
                        name='role'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            id='role'
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            error={Boolean(errors.role)}
                          >
                            {Object.entries(ROLES).map(([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.role && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-role'
                        >
                          {errors.role.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              {watchRole === 'client' && !id && (
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        Codice Identificativo dell&apos;azienda
                      </Typography>
                      <Controller
                        name='companyId'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            id='select-company-id'
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            error={Boolean(errors.companyId)}
                          >
                            {companies?.map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                {company.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.companyId && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-companyId'
                        >
                          {errors.companyId.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Button
          disabled={isSubmitting}
          type='submit'
          variant='contained'
          sx={{ marginTop: '24px' }}
          startIcon={<AddIcon />}
        >
          {!userData ? 'Aggiungi utente' : 'Modificata utente'}
        </Button>
      </Box>
    </RequestHandler>
  );
};

UserInfoForm.propTypes = {
  userData: PropTypes.object,
  companiesData: PropTypes.object,
};
