import { Button } from '@mui/material';

import { MainLayout } from '../../components';
import { TYPE_MODAL } from '../../constants/constants';
import { useModalContext } from '../../contexts';

export const DevPage = () => {
  const { handleChangeModalParameters } = useModalContext();

  const openTestModal = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.TEST,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'test_value',
        value: 'test_value',
      },
      {
        key: 'onCallBack',
        value: () => alert('test'),
      },
    ]);
  };

  return (
    <MainLayout>
      DevPage
      <Button onClick={openTestModal}> open modal example</Button>
    </MainLayout>
  );
};
