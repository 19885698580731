import React, { useState } from 'react';

import {
  useUpdateDealReportStatusAsAdminMutation,
  useUpdateDealReportStatusAsConsultantMutation,
} from './useMutations';

export const useUpdateDealReportStatus = (
  dealReportId,
  isAdmin,
  isConsultant,
  input = {},
) => {
  const [newStatus, setNewStatus] = useState('');

  const [
    updateDealReportStatusAsConsultantMutation,
    { loading: updateDealReportStatusAsConsultantLoading },
  ] = useUpdateDealReportStatusAsConsultantMutation(input);

  const [
    updateDealReportStatusAsAdminMutation,
    { loading: updateDealReportStatusAsAdminLoading },
  ] = useUpdateDealReportStatusAsAdminMutation(input);

  const handleUpdateStatusDealReport = () => {
    if (isConsultant) {
      updateDealReportStatusAsConsultantMutation({
        variables: { input: { dealReportId, newStatus } },
      });
      setNewStatus('');
    }
    if (isAdmin) {
      updateDealReportStatusAsAdminMutation({
        variables: { input: { dealReportId, newStatus } },
      });
      setNewStatus('');
    }
  };

  return [
    newStatus,
    setNewStatus,
    handleUpdateStatusDealReport,
    updateDealReportStatusAsConsultantLoading,
    updateDealReportStatusAsAdminLoading,
  ];
};
