import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

export const ModalContext = React.createContext();

export function useModalContext() {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModalContext must be used within a AuthProvider');
  }
  return modalContext;
}

export function ModalContextProvider({ children }) {
  const [modalParameters, setModalParameters] = useState({
    onCallback: () => {},
    value: '',
    type: '',
    isOpen: false,
  });

  const handleChangeModalParameters = (params) => {
    params.map(({ key, value }) => {
      return setModalParameters((prevModalParameters) => ({
        ...prevModalParameters,
        [key]: value,
      }));
    });
  };

  return (
    <ModalContext.Provider
      value={{
        isAuth: false,
        handleChangeModalParameters: handleChangeModalParameters,
        modalParameters: modalParameters,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

ModalContextProvider.propTypes = {
  children: PropTypes.node,
};
