import * as yup from 'yup';

import { messages as M } from '../messages';

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string(M.PROMPT_MAIL)
    .email(M.PROMPT_MAIL)
    .required(M.REQUIRE_MESSAGE),
});
