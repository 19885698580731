import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export const MainLayout = ({ children }) => {
  return <Box pb={'24px'}>{children}</Box>;
};

MainLayout.propTypes = {
  children: PropTypes.node,
};
