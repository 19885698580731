import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { dealIteractionSchema } from '../../validation';

export const AddDealInteractionModal = ({ isOpen, onClose, onSubmit }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(dealIteractionSchema),
    defaultValues: {
      text: '',
      type: '',
    },
  });

  const handleOnSubmit = async (values) => {
    await onSubmit(values);
    onClose();
    reset();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={handleOnClose}
    >
      <DialogTitle>Aggiungere interazione</DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          <Stack spacing={2}>
            <InputLabel htmlFor='deal-gross'>Testo</InputLabel>
            <OutlinedInput
              id='interections-text'
              placeholder='Testo'
              fullWidth
              error={Boolean(errors.text)}
              {...register('text')}
            />
            {errors.text && (
              <FormHelperText error id='standard-weight-helper-text'>
                {errors.text.message.toString()}
              </FormHelperText>
            )}
          </Stack>
          <Stack spacing={2}>
            <InputLabel htmlFor='deal-type-selector'>Tipo di affare</InputLabel>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Select error={Boolean(errors.type)} {...field}>
                  <MenuItem value='call'>Chiamata</MenuItem>
                  <MenuItem value='email'>E-mail</MenuItem>
                  <MenuItem value='messenger'>Messaggero</MenuItem>
                </Select>
              )}
            />
            {errors.type && (
              <FormHelperText error id='standard-weight-helper-text'>
                {errors.type.message.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} type='submit'>
          Aggiungere
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDealInteractionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dealTypesData: PropTypes.object,
  isDealTypesLoading: PropTypes.bool,
  companiesData: PropTypes.object,
  isCompaniesLoading: PropTypes.bool,
};
