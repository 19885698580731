import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  OutlinedInput,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  ChatAvatar,
  ChatMessage,
  NoDataText,
  RequestHandler,
} from '../../components';
import {
  useReadDealMessageById,
  useReadLatestDealMessagesByDealChatId,
} from '../../hooks';
import { useChat } from '../../hooks/useChat';
import { useNewDealMessageSubscription } from '../../hooks/useSubscriptions';

export const ProjectChat = ({ user }) => {
  const { data: newDealMessageData } = useNewDealMessageSubscription();

  const isUserAdmin = user?.role === 'admin';

  const { projectChatId: chatId } = useParams();

  const chatRef = useRef(null);

  const { messages, initialMessages, addMessage, setMessages, loading } =
    useChat(+chatId, chatRef);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const [readLatestDealMessagesByDealChatIdMutation] =
    useReadLatestDealMessagesByDealChatId();

  const [readDealMessageByIdMutation] = useReadDealMessageById();

  const readMessageById = useCallback(() => {
    readDealMessageByIdMutation({
      variables: {
        dealMessageId: newDealMessageData.newDealMessage.id,
      },
    });
  }, [newDealMessageData, readDealMessageByIdMutation]);

  const readChat = useCallback(() => {
    readLatestDealMessagesByDealChatIdMutation({
      variables: {
        dealChatId: +chatId,
      },
    });
  }, [chatId, readLatestDealMessagesByDealChatIdMutation]);

  useEffect(() => {
    if (!newDealMessageData) return;
    setMessages((prev) => [{ ...newDealMessageData.newDealMessage }, ...prev]);
  }, [newDealMessageData]);

  useEffect(() => {
    if (+chatId === newDealMessageData?.newDealMessage?.dealChatId)
      readMessageById();
  }, [readMessageById, newDealMessageData, chatId]);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, [messages, initialMessages, chatRef?.current]);

  useEffect(() => {
    if ((messages?.length > 0 || initialMessages?.length > 0) && !isUserAdmin) {
      readChat();
    }
  }, [chatId, readChat, messages?.length, initialMessages?.length]);

  const onSubmit = async ({ message }) => {
    addMessage(message);
    reset();
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <ChatAvatar
          firstName={user.firstName}
          lastName={user.lastName}
          uri={user.uri}
        />
        <Divider sx={{ marginTop: '24px' }} />

        <Stack
          pt='30px'
          spacing={2}
          justifyContent='space-between'
          sx={{
            height: '70%',
          }}
        >
          <Box
            sx={{
              overflowY: 'auto',
              padding: '5px',
              height: 'calc(70vh - 300px)',
            }}
            ref={chatRef}
          >
            <RequestHandler loading={loading}>
              <Stack spacing={1}>
                {messages?.length ? (
                  messages
                    ?.map((message) => (
                      <ChatMessage
                        key={message.id}
                        user={message.user}
                        messageText={message.text}
                        date={message.createdAt}
                        isyou={user.id === message.user.id}
                      />
                    ))
                    .reverse()
                ) : (
                  <NoDataText message={'Ancora nessun messaggi...'} />
                )}
              </Stack>
            </RequestHandler>
          </Box>
          {isUserAdmin ? null : (
            <Box
              sx={{
                display: 'flex',
              }}
              component={'form'}
              onSubmit={handleSubmit(onSubmit)}
            >
              <OutlinedInput
                id='message'
                type='text'
                placeholder='Enter message'
                fullWidth
                autoComplete='message'
                error={Boolean(errors.message)}
                {...register('message')}
              />
              {errors.firstName && (
                <FormHelperText
                  error
                  id='standard-weight-helper-text-first-name'
                >
                  {errors.message.message.toString()}
                </FormHelperText>
              )}

              <Button
                disabled={!watch('message')}
                sx={{ marginLeft: '12px' }}
                type='submit'
                variant='contained'
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

ProjectChat.propTypes = {
  user: PropTypes.object,
};
