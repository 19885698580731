import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { palette } from '../../theme/foundations';

export const EditDealDocumentDatesModal = ({
  isOpen,
  onClose,
  onSubmit,
  startedAt,
  endedAt,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startedAt: startedAt || null,
      endedAt: endedAt || null,
    },
  });
  const handleOnSubmit = async (values) => {
    await onSubmit(values);
    onClose();
    reset();
  };

  const handleOnClose = async () => {
    await onClose();
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={handleOnClose}
    >
      <DialogContent
        sx={{ paddingTop: '24px !important', paddingBottom: '24px !important' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                iniziato alle
              </Typography>
              <Controller
                name='startedAt'
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      error={Boolean(errors.startedAt)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors.startedAt && (
                <FormHelperText
                  error
                  id='standard-weight-helper-text-startedAt'
                >
                  {errors.startedAt.message.toString()}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Finito alle
              </Typography>
              <Controller
                name='endedAt'
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      error={Boolean(errors.endedAt)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors.endedAt && (
                <FormHelperText error id='standard-weight-helper-text-endedAt'>
                  {errors.endedAt.message.toString()}
                </FormHelperText>
              )}
            </FormControl>
            <Button type='submit' variant='contained'>
              Modificare
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

EditDealDocumentDatesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
};
