import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { MainLayout, RequestHandler } from '../../components';
import { ROUTES } from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import {
  useGetClientsAsConsultantQuery,
  useGetClientsQuery,
  useGetCompaniesAsAdminQuery,
  useGetDealsQuery,
  useGetLastDealReportsAsAdmin,
  useGetLastDealReportsAsClient,
  useGetLastDealReportsAsConsultant,
  useGetUsersQuery,
} from '../../hooks';
import { palette } from '../../theme/foundations';
import { DashboardDiagram } from './DashboardDiagram';
import { DashboardProject } from './DashboardProject';
import { DashboardReport } from './DashboardReport';

export const Dashboard = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [clientId, setclientId] = useState(0);
  const isConsultant = user.role === 'consultant';
  const isClient = user.role === 'client';
  const isAdmin = user.role === 'admin';

  const { data: usersData, loading: usersLoading } = useGetClientsQuery({
    skip: !isAdmin,
  });

  const { data: clientsAsConsultantData, loading: clientsAsConsultantLoading } =
    useGetClientsAsConsultantQuery({ fetchPolicy: 'network-only' });

  const {
    data: lastDealReportAsClientData,
    loading: lastDealReportAsClientLoading,
  } = useGetLastDealReportsAsClient({ fetchPolicy: 'network-only' });

  const {
    data: lastDealReportAsConsultantData,
    loading: lastDealReportAsConsultantLoading,
  } = useGetLastDealReportsAsConsultant({
    clientId,
    fetchPolicy: 'network-only',
  });

  const {
    data: lastDealReportAsAdminData,
    loading: lastDealReportAsAdminLoading,
  } = useGetLastDealReportsAsAdmin({
    clientId,
    fetchPolicy: 'network-only',
  });

  const { data: dealsData, loading: dealsLoading } = useGetDealsQuery({
    limit: 5,
    fetchPolicy: 'network-only',
    clientId,
  });

  const users =
    usersData?.getUsers?.users ||
    clientsAsConsultantData?.getClientsAsConsultant;

  function handleClickEditButton(projectId, projectChatId) {
    navigate(`${ROUTES.project}/${projectId}/${projectChatId}`);
  }

  return (
    <RequestHandler
      loading={
        lastDealReportAsClientLoading ||
        lastDealReportAsConsultantLoading ||
        dealsLoading ||
        clientsAsConsultantLoading
      }
    >
      <MainLayout>
        <Typography mb={3}>Dashboard</Typography>
        {!isClient ? (
          <Box mb={3}>
            {!isClient ? (
              <FormControl>
                <Typography sx={{ color: palette.text.typography }}>
                  ID del cliente principale
                </Typography>
                <Select
                  id='select-consultant-id'
                  name='clientId'
                  onChange={(e) => {
                    setclientId(e.target.value);
                  }}
                  value={clientId}
                >
                  {users?.map(({ id, firstName, lastName }) => (
                    <MenuItem key={id} value={id}>
                      {firstName} {lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Box>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12} mb={3}>
            <Grid container spacing={3} direction='row'>
              <Grid item xs={4}>
                <DashboardDiagram
                  company={
                    lastDealReportAsClientData?.getLastDealReportsAsClient[0]
                      ?.deal?.company ||
                    lastDealReportAsConsultantData
                      ?.getLastDealReportsAsConsultant[0]?.deal?.company ||
                    lastDealReportAsAdminData?.getLastDealReportsAsAdmin[0]
                      ?.deal?.company
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <DashboardReport
                  reports={
                    lastDealReportAsClientData?.getLastDealReportsAsClient ||
                    lastDealReportAsConsultantData?.getLastDealReportsAsConsultant ||
                    lastDealReportAsAdminData?.getLastDealReportsAsAdmin
                  }
                  isClient={isClient}
                  isConsultant={isConsultant}
                  isAdmin={isAdmin}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DashboardProject
              projects={dealsData?.getDeals?.deals}
              isClient={isClient}
              onClick={handleClickEditButton}
            />
          </Grid>
        </Grid>
      </MainLayout>
    </RequestHandler>
  );
};
