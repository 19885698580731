import { Avatar, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const ChatAvatar = ({ uri, firstName, lastName }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Avatar
        sx={{ marginRight: '10px' }}
        alt={`${firstName} ${lastName}`}
        src={uri}
      />
      <Box>
        <Box>
          {firstName} {lastName}
        </Box>
      </Box>
    </Box>
  );
};

ChatAvatar.propTypes = {
  uri: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
