import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { usePasswordResetMutation } from '../../hooks';
import { getErrorText } from '../../utils';
import { newPasswordSchema } from '../../validation';

export const NewPasswordForm = () => {
  const [passwordReset, { error: requestError }] = usePasswordResetMutation();

  const [searchParams] = useSearchParams();

  const [showSubmitError, setShowSubmitError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(newPasswordSchema),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = async (values) => {
    setShowSubmitError(true);
    await passwordReset({
      variables: {
        newPassword: values.password,
        passwordResetToken: searchParams.get('token'),
      },
    });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor='password-login'>Nuova password</InputLabel>
          <OutlinedInput
            fullWidth
            error={Boolean(errors.password)}
            id='-password-login'
            type={showPassword ? 'text' : 'password'}
            onFocus={() => setShowSubmitError(false)}
            autoComplete='current-password'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            placeholder='********'
            {...register('password')}
          />
          {errors.password && (
            <FormHelperText
              error
              id='standard-weight-helper-text-password-login'
            >
              {errors.password.message.toString()}
            </FormHelperText>
          )}
        </Stack>
        {requestError && showSubmitError && (
          <FormHelperText style={{ textAlign: 'center' }} error>
            {getErrorText(requestError?.message)}
          </FormHelperText>
        )}
        <Stack>
          <Button
            disabled={isSubmitting}
            fullWidth
            type='submit'
            variant='contained'
          >
            Accedere
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
