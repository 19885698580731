import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { palette } from '../../theme/foundations';
import { addDocumentSchema } from '../../validation/schema/addDocumentSchema';

export const AddDocumentModal = ({ isOpen, onClose, onSubmit, loading }) => {
  const [typeOfAdding, setTypeOfAdding] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addDocumentSchema),
    defaultValues: {
      file: null,
      externalLink: null,
      startedAt: null,
      endedAt: null,
    },
  });

  const handleOnSubmit = async (values) => {
    await onSubmit(values);
    onClose();
    reset();
    setTypeOfAdding(null);
  };

  const handleOnClose = async () => {
    await onClose();
    setTypeOfAdding(null);
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={handleOnClose}
    >
      <DialogContent
        sx={{ paddingTop: '24px !important', paddingBottom: '24px !important' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          {typeOfAdding ? (
            <Stack spacing={2}>
              {typeOfAdding === 1 ? (
                <>
                  <Button variant='contained' component='label'>
                    Upload File
                    <input
                      accept='.doc,.docx,.pdf'
                      type='file'
                      {...register('file')}
                      hidden
                      name='file'
                    />
                  </Button>
                  {errors.file && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-external-link'
                    >
                      {errors.file.message.toString()}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <OutlinedInput
                    id='external-link'
                    placeholder='Collegamento file'
                    fullWidth
                    {...register('externalLink')}
                  />
                  {errors.externalLink && (
                    <FormHelperText
                      error
                      id='standard-weight-helper-external-link'
                    >
                      {errors.externalLink.message.toString()}
                    </FormHelperText>
                  )}
                </>
              )}
              <FormControl fullWidth>
                <Typography sx={{ color: palette.text.typography }}>
                  iniziato alle
                </Typography>
                <Controller
                  name='startedAt'
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        error={Boolean(errors.startedAt)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors.startedAt && (
                  <FormHelperText
                    error
                    id='standard-weight-helper-text-startedAt'
                  >
                    {errors.startedAt.message.toString()}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <Typography sx={{ color: palette.text.typography }}>
                  Finito alle
                </Typography>
                <Controller
                  name='endedAt'
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        inputRef={ref}
                        error={Boolean(errors.endedAt)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors.endedAt && (
                  <FormHelperText
                    error
                    id='standard-weight-helper-text-endedAt'
                  >
                    {errors.endedAt.message.toString()}
                  </FormHelperText>
                )}
              </FormControl>
              <Button disabled={loading} type='submit' variant='contained'>
                Aggiungi file
              </Button>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Button variant='contained' onClick={() => setTypeOfAdding(1)}>
                Aggiungi file
              </Button>
              <Button variant='contained' onClick={() => setTypeOfAdding(2)}>
                Aggiungere un collegamento a un file
              </Button>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

AddDocumentModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
