import PropTypes from 'prop-types';
import React from 'react';

import { MainLayout, RequestHandler } from '../../components';
import { useAuthContext } from '../../contexts';
import {
  useGetCompaniesAsAdminQuery,
  useGetCompanyByIdQuery,
  useGetUserByIdQuery,
} from '../../hooks';
import { CompanyInfoForm, UserInfoForm } from './components';

export const UserWrapper = () => {
  const { user } = useAuthContext();

  const { data: userData, loading: userDataLoading } = useGetUserByIdQuery();

  const { data: companiesData, loading: companiesDataLoading } =
    useGetCompaniesAsAdminQuery({ isLimit: false });

  const { data: companyById, loading: companyByIdLoading } =
    useGetCompanyByIdQuery(user?.companyId);
  return (
    <RequestHandler
      loading={userDataLoading || companiesDataLoading || companyByIdLoading}
    >
      <User
        userData={userData}
        companiesData={companiesData}
        companyById={companyById}
      />
    </RequestHandler>
  );
};

const User = ({ userData, companiesData, companyById }) => {
  return (
    <MainLayout isForm>
      <UserInfoForm userData={userData} companiesData={companiesData} />
      {userData?.getUserById?.role !== 'admin' && (
        <CompanyInfoForm userData={userData} companyById={companyById} />
      )}
    </MainLayout>
  );
};

User.propTypes = {
  userData: PropTypes.object,
  companiesData: PropTypes.object,
  companyById: PropTypes.object,
};
