import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainLayout, RequestHandler, TableSortCell } from '../../components';
import { CheckAdmin } from '../../components/CheckAdmin/CheckAdmin';
import {
  COMPANY_SIZE,
  LIMIT_ITEM_PAGE,
  ROUTES,
} from '../../constants/constants';
import {
  useFilter,
  useGetCompaniesAsAdminQuery,
  useGetCompaniesAsConsultantQuery,
} from '../../hooks';

const headCells = [
  {
    sortId: 'name',
    label: 'Nome',
    align: 'start',
  },
  {
    label: 'CF',
  },
  {
    label: 'Posizione',
  },
  {
    label: 'Taglia',
  },
  {
    label: 'Consulente principale',
  },
  {
    label: 'dmUsedPercentage',
  },
  {
    label: 'dmUsed',
  },
  {
    label: 'dmYearN',
  },
  {
    label: 'dmYearN1',
  },
  {
    label: 'dmYearN2',
  },
  {
    label: 'yearN',
  },
  {
    label: 'yearN1',
  },
  {
    label: 'yearN2',
  },
  {
    sortId: 'createdAt',
    label: 'Creato',
  },
];

export const Companies = () => {
  const navigate = useNavigate();

  const { filters, setFilters } = useFilter();
  const [pagePagination, setPagePagination] = useState(1);

  const { data: companiesAsAdminData, loading: companiesAsAdminLoading } =
    useGetCompaniesAsAdminQuery({ pagePagination });
  const {
    data: companiesAsConsultantData,
    loading: companiesAsConsultantLoading,
  } = useGetCompaniesAsConsultantQuery({ pagePagination });

  const companies =
    companiesAsAdminData?.getCompaniesAsAdmin ||
    companiesAsConsultantData?.getCompaniesAsConsultant;

  const handleClickAddButton = () => {
    navigate(ROUTES.company);
  };
  const handleClickEditButton = (id) => {
    navigate(`${ROUTES.company}/${id}`);
  };

  return (
    <RequestHandler
      loading={companiesAsAdminLoading || companiesAsConsultantLoading}
      isNoData={!companies?.companies?.length}
    >
      <MainLayout>
        <CheckAdmin>
          <Button
            sx={{ marginBottom: '24px' }}
            variant='contained'
            startIcon={<AddIcon />}
            onClick={handleClickAddButton}
          >
            Aggiungi azienda
          </Button>
        </CheckAdmin>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableSortCell
                    align={headCell?.align}
                    order={filters.order}
                    field={filters.field}
                    setFilters={setFilters}
                    key={headCell.label}
                    label={headCell.label}
                    sortId={headCell?.sortId}
                  />
                ))}
                <CheckAdmin>
                  <TableCell align='center'></TableCell>
                </CheckAdmin>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.companies?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell align='center'>{item.cf}</TableCell>
                  <TableCell align='center'>{item.location}</TableCell>
                  <TableCell align='center'>
                    {COMPANY_SIZE[item.size]}
                  </TableCell>
                  <TableCell align='center'>
                    {item.leadConsultant?.firstName}{' '}
                    {item.leadConsultant?.lastName}
                  </TableCell>
                  <TableCell align='center'>{item.dmUsedPercentage}</TableCell>
                  <TableCell align='center'>{item.dmUsed}</TableCell>
                  <TableCell align='center'>{item.dmYearN}</TableCell>
                  <TableCell align='center'>{item.dmYearN1}</TableCell>
                  <TableCell align='center'>{item.dmYearN2}</TableCell>
                  <TableCell align='center'>{item.yearN}</TableCell>
                  <TableCell align='center'>{item.yearN1}</TableCell>
                  <TableCell align='center'>{item.yearN2}</TableCell>
                  <TableCell align='center'>
                    {format(new Date(parseInt(item.createdAt)), 'dd.MM.yyyy')}
                  </TableCell>
                  <CheckAdmin>
                    <TableCell align='center'>
                      <IconButton
                        onClick={() => {
                          handleClickEditButton(item.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </CheckAdmin>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {companies?.companies?.length ? (
          <Box mt={4}>
            <Pagination
              count={
                companies?.count
                  ? Math.ceil(companies?.count / LIMIT_ITEM_PAGE)
                  : 1
              }
              page={pagePagination}
              onChange={(_, page) => {
                setPagePagination(page);
              }}
              variant='outlined'
              color='primary'
            />
          </Box>
        ) : null}
      </MainLayout>
    </RequestHandler>
  );
};
