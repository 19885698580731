import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useGetDealChatsQuery } from '../hooks';
import { useNewDealMessageSubscription } from '../hooks/useSubscriptions';

const ChatContext = createContext();

const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

const ChatContextProvider = ({ children }) => {
  const [chatId, setChatId] = useState(null);
  const [pagePagination, setPagePagination] = useState(1);
  const chatIdStore = localStorage.getItem('chatId');

  const {
    data: chatsData,
    loading: chatsLoading,
    refetch,
  } = useGetDealChatsQuery({
    pagePagination,
  });

  const { data: newDealMessageData } = useNewDealMessageSubscription();

  useEffect(() => {
    refetch();
  }, [newDealMessageData]);

  useEffect(() => {
    if (!chatIdStore) return;
    setChatId(Number(chatIdStore));
  }, [chatIdStore]);

  return (
    <ChatContext.Provider
      value={{
        pagePagination,
        setPagePagination,
        chatId,
        setChatId,
        chatsData,
        chatsLoading,
        newDealMessageData,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ChatContextProvider, useChatContext };
