import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { NoDataText } from '../NoDataText/NoDataText';

export const RequestHandler = ({
  loading,
  children,
  isNoData,
  height = 'auto',
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isNoData && !loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height,
        }}
      >
        <NoDataText />
      </Box>
    );
  }

  return children;
};

RequestHandler.propTypes = {
  loading: PropTypes.bool,
  isNoData: PropTypes.bool,
  height: PropTypes.string,
  children: PropTypes.node,
};
