import { useEffect, useState } from 'react';

import { useSendDealMessageMutation } from './useMutations';
import { useGetDealChatByIdLazyQuery } from './useQueries';

export const useChat = (chatId, ref) => {
  const [getDealChatByIdLazyQuery, { data: chatData, loading }] =
    useGetDealChatByIdLazyQuery({
      chatId,
    });

  const [messages, setMessages] = useState([]);

  const [sendDealMessageMutation, { loading: sendMessageLoading }] =
    useSendDealMessageMutation();

  useEffect(() => {
    if (!chatId) return;
    getDealChatByIdLazyQuery({
      variables: {
        dealChatId: +chatId,
      },
    });
  }, [chatId]);

  useEffect(() => {
    if (!chatData) return;
    setMessages(chatData?.getDealChatById?.dealMessagesWithCount?.dealMessages);
  }, [chatData]);
  const addMessage = (text) => {
    if (ref?.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }

    sendDealMessageMutation({
      variables: { input: { dealChatId: Number(chatId), text } },
    });
  };

  return {
    messages,
    loading,
    sendMessageLoading,
    chatData,
    addMessage,
    setMessages,
  };
};
