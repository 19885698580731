import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { drawerWidth, navLinkItems } from '../../constants/constants';
import { Header } from '../Header/Header';
import { RolesWrapper } from '../RolesWrapper/RolesWrapper';
import { DrawerHeader, Main } from './styles';

export const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDrawerOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header handleDrawerOpen={handleDrawerOpen} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            marginTop: '60px',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={isOpen}
      >
        <List>
          {navLinkItems.map((item) => (
            <RolesWrapper key={item.text} rolesArray={item.roles}>
              <ListItem disablePadding>
                <NavLink
                  to={item.path}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {({ isActive }) => (
                    <ListItemButton selected={isActive}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          color: isActive ? 'green' : 'black',
                          padding: '8px',
                        }}
                      >
                        {item.text}
                      </ListItemText>
                    </ListItemButton>
                  )}
                </NavLink>
              </ListItem>
            </RolesWrapper>
          ))}
        </List>
      </Drawer>
      <Main isOpen={isOpen}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

SideBar.propTypes = {
  children: PropTypes.node,
};
