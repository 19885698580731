import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MainLayout, RequestHandler, TableSortCell } from '../../components';
import { CheckAdmin } from '../../components/CheckAdmin/CheckAdmin';
import { LIMIT_ITEM_PAGE, ROLES, ROUTES } from '../../constants/constants';
import {
  useFilter,
  useGetClientsAsConsultantQuery,
  useGetUsersQuery,
} from '../../hooks';

const headCells = [
  {
    label: 'Nome',
  },
  {
    label: 'Cognome',
  },
  {
    label: 'E-mail',
  },
  {
    label: 'Numero di cellulare',
  },
  {
    label: 'Numero di telefono fisso',
  },
  {
    label: 'Ruolo',
  },
  {
    label: "Codice Identificativo dell'azienda",
  },
  {
    sortId: 'createdAt',
    label: 'Creato',
  },
];

export const Users = () => {
  const navigate = useNavigate();

  const { filters, setFilters } = useFilter();
  const [pagePagination, setPagePagination] = useState(1);

  const { data: usersData, loading: usersLoading } = useGetUsersQuery({
    pagePagination,
  });
  const { data: clientsAsConsultantData, loading: clientsAsConsultantLoading } =
    useGetClientsAsConsultantQuery();

  const users =
    usersData?.getUsers?.users ||
    clientsAsConsultantData?.getClientsAsConsultant;

  const handleClickAddButton = () => {
    navigate(ROUTES.user);
  };
  const handleClickEditButton = (id) => {
    navigate(`${ROUTES.user}/${id}`);
  };

  return (
    <RequestHandler
      loading={clientsAsConsultantLoading || usersLoading}
      isNoData={!users?.length}
    >
      <MainLayout>
        <CheckAdmin>
          <Button
            sx={{ marginBottom: '24px' }}
            variant='contained'
            startIcon={<AddIcon />}
            onClick={handleClickAddButton}
          >
            Aggiungi utente
          </Button>
        </CheckAdmin>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableSortCell
                    order={filters.order}
                    field={filters.field}
                    setFilters={setFilters}
                    key={headCell.label}
                    label={headCell.label}
                    sortId={headCell?.sortId}
                  />
                ))}
                <CheckAdmin>
                  <TableCell align='center'></TableCell>
                </CheckAdmin>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{item.firstName}</TableCell>
                  <TableCell align='center'>{item.lastName}</TableCell>
                  <TableCell align='center'>{item.email}</TableCell>
                  <TableCell align='center'>{item.cellPhone}</TableCell>
                  <TableCell align='center'>{item.landlinePhone}</TableCell>
                  <TableCell align='center'>{ROLES[item.role]}</TableCell>
                  <TableCell align='center'>
                    {item?.companyInWhichAsClient?.name}
                  </TableCell>
                  <TableCell align='center'>
                    {format(new Date(parseInt(item.createdAt)), 'dd.MM.yyyy')}
                  </TableCell>
                  <CheckAdmin>
                    <TableCell align='center'>
                      <IconButton
                        onClick={() => {
                          handleClickEditButton(item.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </CheckAdmin>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {users?.length ? (
          <Box mt={4}>
            <Pagination
              count={
                usersData?.getUsers?.count
                  ? Math.ceil(usersData?.getUsers?.count / LIMIT_ITEM_PAGE)
                  : 1
              }
              page={pagePagination}
              onChange={(_, page) => {
                setPagePagination(page);
              }}
              variant='outlined'
              color='primary'
            />
          </Box>
        ) : null}
      </MainLayout>
    </RequestHandler>
  );
};
