import React, { useState } from 'react';

import {
  useUploadDocumentFileMutation,
  useUploadDocumentLinkMutation,
} from './useMutations';

export const useUploadDocumentFile = (dealDocumentId) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [uploadFile, { loading: uploadFileLoading }] =
    useUploadDocumentFileMutation();

  const [uploadLink, { loading: uploadLinkLoading }] =
    useUploadDocumentLinkMutation();

  const onSubmit = async ({ file, externalLink, startedAt, endedAt }) => {
    const uploadClientfile = file?.[0];
    if (file) {
      await uploadFile({
        variables: {
          dealDocumentId,
          input: {
            file: uploadClientfile,
            startedAt,
            endedAt,
          },
        },
      });
    } else {
      await uploadLink({
        variables: {
          dealDocumentId,
          input: {
            externalLink,
            startedAt,
            endedAt,
          },
        },
      });
    }
  };

  return [
    onSubmit,
    uploadFileLoading,
    uploadLinkLoading,
    isModalOpen,
    setIsModalOpen,
  ];
};
