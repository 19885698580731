import { TableCell, TableSortLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const TableSortCell = ({
  label,
  sortId,
  order,
  field,
  setFilters,
  align = 'center',
}) => {
  const getDirection = () => {
    if (field === sortId) {
      return order === 'desc' ? 'asc' : 'desc';
    }
    return 'desc';
  };

  const handleSortClick = () => {
    if (!sortId) return null;
    setFilters({ field: sortId, order: getDirection() });
  };

  if (!sortId) {
    return (
      <TableCell sx={{ fontWeight: 700 }} align={align}>
        {label}
      </TableCell>
    );
  }

  return (
    <TableCell
      sx={{ fontWeight: 700 }}
      align={align}
      sortDirection={field === sortId ? order : false}
    >
      <TableSortLabel
        active={field === sortId}
        direction={field === sortId ? order : 'desc'}
        onClick={handleSortClick}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
};

TableSortCell.propTypes = {
  label: PropTypes.string.isRequired,
  sortId: PropTypes.string,
  order: PropTypes.string,
  align: PropTypes.string,
  field: PropTypes.string,
  setFilters: PropTypes.func,
};
