import React, { useState } from 'react';

import { useRequestDealReportAsClientMutation } from './useMutations';

export const useRequestDealReport = (input = {}) => {
  const [dealReportType, setDealReportType] = useState('');

  const [
    requestDealReportAsClientMutation,
    { loading: requestDealReportAsClientLoading },
  ] = useRequestDealReportAsClientMutation(input);

  const handleRequestDealReportAsClient = (type, companyId) => {
    requestDealReportAsClientMutation({
      variables: { input: { type, companyId } },
    });
  };

  return [
    dealReportType,
    setDealReportType,
    requestDealReportAsClientLoading,
    handleRequestDealReportAsClient,
  ];
};
