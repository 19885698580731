export const getColorStatusDoc = (status) => {
  switch (status) {
    case 'valid':
      return 'info';
    case 'outdated':
      return 'warning';
    case 'missing':
      return 'error';
    case 'incomplete':
      return 'success';
  }
};
