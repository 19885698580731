import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { convertToInt } from '../utils';

export const useFilter = () => {
  const { search, pathname } = useLocation();

  const navigate = useNavigate();

  return {
    filters: convertToInt(queryString.parse(search)) || {},
    setFilters: (values) =>
      navigate(
        pathname +
          '?' +
          queryString.stringify({ ...queryString.parse(search), ...values }),
      ),
    clearFilters: () => navigate(pathname),
  };
};
