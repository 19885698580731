import * as yup from 'yup';

import { messages as M } from '../messages';

export const addDealDocumentValidationSchema = yup.object().shape({
  name: yup.string().required(M.REQUIRE_MESSAGE),
  startedAt: yup.string().nullable().required(M.REQUIRE_MESSAGE),
  endedAt: yup.string().nullable().required(M.REQUIRE_MESSAGE),
  dealId: yup.number().nullable().required(M.REQUIRE_MESSAGE),
});
