import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useRequestPasswordResetMutation } from '../../hooks';
import { getErrorText } from '../../utils';
import { forgotPasswordSchema } from '../../validation';

export const ForgotPasswordForm = () => {
  const [requestPasswordReset, { error: requestError }] =
    useRequestPasswordResetMutation();

  const [showSubmitError, setShowSubmitError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (values) => {
    setShowSubmitError(true);
    await requestPasswordReset({ variables: values });
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor='email-login'>Indirizzo e-mail</InputLabel>
          <OutlinedInput
            id='email-login'
            type='email'
            placeholder='info@email.com'
            fullWidth
            onFocus={() => setShowSubmitError(false)}
            error={Boolean(errors.email)}
            {...register('email')}
          />
          {errors.email && (
            <FormHelperText error id='standard-weight-helper-text-email-login'>
              {errors.email.message.toString()}
            </FormHelperText>
          )}
        </Stack>
        {requestError && showSubmitError && (
          <FormHelperText style={{ textAlign: 'center' }} error>
            {getErrorText(requestError?.message)}
          </FormHelperText>
        )}
        <Stack>
          <Button
            disabled={isSubmitting}
            fullWidth
            type='submit'
            variant='contained'
          >
            Invia e-mail di reimpostazione password
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
