export const getColorStatusReports = (status) => {
  switch (status) {
    case 'requested':
      return 'error';
    case 'workInProgress':
      return 'warning';
    case 'pending':
      return 'warning';
    case 'docsNeeded':
      return 'info';
    case 'ready':
      return 'success';
    default:
      return 'primary';
  }
};
