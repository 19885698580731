import PropTypes from 'prop-types';

import { useAuthContext } from '../../contexts';

export const CheckAdmin = ({ children }) => {
  const { user } = useAuthContext();

  if (user.role !== 'admin') return null;

  return children;
};

CheckAdmin.propTypes = {
  children: PropTypes.node,
};
