import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment userFragment on UserEntity {
    id
    companyId
    email
    firstName
    lastName
    role
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyFragment on CompanyEntity {
    id
    name
    cf
    location
    size
    leadConsultantId
    dmUsedPercentage
    dmUsed
    dmYearN
    dmYearN1
    dmYearN2
    yearN
    yearN1
    yearN2
    createdAt
  }
`;

export const DEAL_FRAGMENT = gql`
  fragment dealFragment on DealEntity {
    id
    companyId
    dealTypeId
    name
    status
    createdAt
    submittedAt
    approvedAt
    gross
    net
  }
`;

export const DEAL_CONSULTANT_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment dealConsultantFragment on DealConsultantAssistantEntity {
    id
    consultantId
    consultant {
      ...userFragment
    }
  }
`;

export const DEAL_INTERACTION_FRAGMENT = gql`
  fragment dealInteractionFragment on DealInteractionLogEntity {
    id
    text
    type
    createdAt
  }
`;

export const DEAL_DOCUMENT_FRAGMENT = gql`
  fragment dealDocumentFragment on DealDocumentEntity {
    id
    fileName
    externalLink
    createdAt
    status
    name
    dealTypeDocument {
      id
      name
    }
    dealTypeDocumentId
    endedAt
    startedAt
  }
`;

export const DEAL_REPORT_FRAGMENT = gql`
  fragment dealReportFragment on DealReportEntity {
    id
    fileName
    status
    type
    createdAt
    issuedAt
    deal {
      id
      name
    }
  }
`;

export const DEAL_MESSAGE_FRAGMENT = gql`
  fragment dealMessageFragment on DealMessageEntity {
    id
    dealChatId
    isMyRead
    read
    text
    createdAt
  }
`;

export const DEAL_CHAT_FRAGMENT = gql`
  fragment dealChatFragment on DealChatEntity {
    id
    dealId
    createdAt
    unreadDealMessagesCount
  }
`;
