import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { LIMIT_ITEM_PAGE, ROUTES } from '../constants/constants';
import { useAuthContext, useSearchContext } from '../contexts';
import { client } from '../graphql/client';
import * as MUTATIONS from '../graphql/mutations';
import * as QUERIES from '../graphql/queries';
import { getErrorText } from '../utils';
import { useFilter } from './useFilter';

export const useRequestPasswordResetMutation = () => {
  return useMutation(MUTATIONS.REQUEST_PASSWORD_RESET, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Email di recupero password inviata');
      }
    },
  });
};

export const usePasswordResetMutation = () => {
  const { signIn } = useAuthContext();

  return useMutation(MUTATIONS.PASSWORD_RESET, {
    onCompleted: (response) => {
      if (response?.resetUserPassword) {
        localStorage.setItem('token', response.resetUserPassword?.accessToken);
        toast.success('Password salvata con successo!');
        signIn(response.resetUserPassword?.user);
        client.resetStore();
      }
    },
  });
};

export const useCreateUserMutation = () => {
  const navigate = useNavigate();
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  return useMutation(MUTATIONS.CREATE_USER, {
    onCompleted: (response) => {
      if (response) {
        navigate(ROUTES.users);
        switch (response.createUser.role) {
          case 'Admin':
            toast.success('Amministratore aggiunto con successo');
            break;
          case 'Consultant':
            toast.success('Consultante aggiunto con successo');
            break;
          case 'Client':
            toast.success('Cliente aggiunto con successo');
            break;
        }
      }
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    update(cache, { data }) {
      if (!data?.createUser) {
        return null;
      }
      const users = cache.readQuery({
        query: QUERIES.GET_USERS,
        variables: {
          input: {
            offset: 0,
            limit: LIMIT_ITEM_PAGE,
            search: searchValue,
            orderByCreatedAt: filters.order || 'desc',
          },
        },
      });

      if (users) {
        cache.writeQuery({
          query: QUERIES.GET_USERS,
          variables: {
            input: {
              offset: 0,
              limit: LIMIT_ITEM_PAGE,
              search: searchValue,
              orderByCreatedAt: filters.order || 'desc',
            },
          },
          data: {
            ...users,
            getUsers: {
              ...users?.getUsers,
              count: users?.getUsers?.count + 1,
              users: [data?.createUser, ...(users?.getUsers?.users || [])],
            },
          },
        });
      }
    },
  });
};

export const useEditUserMutation = () => {
  const navigate = useNavigate();
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  const { signIn } = useAuthContext();

  return useMutation(MUTATIONS.EDIT_USER, {
    onCompleted: (response) => {
      if (response) {
        navigate(ROUTES.users);
        toast.success('Utente modificata con successo');
        signIn(response.editUser);
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.editUser) {
        return null;
      }
      const users = cache.readQuery({
        query: QUERIES.GET_USERS,
        variables: {
          input: {
            offset: 0,
            limit: LIMIT_ITEM_PAGE,
            search: searchValue,
            orderByCreatedAt: filters.order || 'desc',
          },
        },
      });
      const newUsers = users?.getUsers?.users.map((user) =>
        user.id === data.editUser.id ? { ...data.editUser } : user,
      );

      if (users) {
        cache.writeQuery({
          query: QUERIES.GET_USERS,
          variables: {
            input: {
              offset: 0,
              limit: LIMIT_ITEM_PAGE,
              search: searchValue,
              orderByCreatedAt: filters.order || 'desc',
            },
          },
          data: {
            ...users,
            getUsers: {
              ...users?.getUsers,
              users: newUsers,
            },
          },
        });
      }
    },
  });
};

export const useCreateCompanyMutation = () => {
  const navigate = useNavigate();

  const { searchValue } = useSearchContext();
  const { filters } = useFilter();

  return useMutation(MUTATIONS.CREATE_COMPANY, {
    onCompleted: (response) => {
      if (response) {
        navigate(ROUTES.companies);
        toast.success('Azienda aggiunto con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.createCompany) {
        return null;
      }
      const companies = cache.readQuery({
        query: QUERIES.GET_COMPANIES_AS_ADMIN,
        variables: {
          input: {
            offset: 0,
            limit: LIMIT_ITEM_PAGE,
            searchByName: searchValue || '',
            sortByField: filters.orderBy || 'createdAt',
            orderBy: filters.order || 'desc',
          },
        },
      });

      if (companies) {
        cache.writeQuery({
          query: QUERIES.GET_COMPANIES_AS_ADMIN,
          variables: {
            input: {
              offset: 0,
              limit: LIMIT_ITEM_PAGE,
              searchByName: searchValue || '',
              sortByField: filters.orderBy || 'createdAt',
              orderBy: filters.order || 'desc',
            },
          },
          data: {
            ...companies,
            getCompaniesAsAdmin: {
              ...companies?.getCompaniesAsAdmin,
              count: companies?.getCompaniesAsAdmin?.count + 1,
              companies: [
                data?.createCompany,
                ...(companies?.getCompaniesAsAdmin?.companies || []),
              ],
            },
          },
        });
      }
    },
  });
};

export const useEditCompanyMutation = () => {
  const navigate = useNavigate();

  return useMutation(MUTATIONS.EDIT_COMPANY, {
    onCompleted: (response) => {
      if (response) {
        navigate(ROUTES.companies);
        toast.success('Azienda modificata con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_COMPANIES_AS_ADMIN],
  });
};

export const useCreateDealMutation = () => {
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  return useMutation(MUTATIONS.CREATE_DEAL, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Progetto creato con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(getErrorText(error.message));
      }
    },
    update(cache, { data }) {
      if (!data?.createDeal) {
        return null;
      }
      const deals = cache.readQuery({
        query: QUERIES.GET_DEALS,
        variables: {
          input: {
            offset: 0,
            limit: LIMIT_ITEM_PAGE,
            search: searchValue,
            sort: filters,
          },
        },
      });

      if (deals) {
        cache.writeQuery({
          query: QUERIES.GET_DEALS,
          variables: {
            input: {
              offset: 0,
              limit: LIMIT_ITEM_PAGE,
              search: searchValue,
              sort: filters,
            },
          },
          data: {
            ...deals,
            getDeals: {
              ...deals?.getDeals,
              count: deals?.getDeals?.count + 1,
              deals: [data?.createDeal, ...(deals?.getDeals?.deals || [])],
            },
          },
        });
      }
    },
  });
};

export const useAddDealInteractionMutation = () => {
  return useMutation(MUTATIONS.ADD_DEAL_INTERACTION, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Interazione aggiunta con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_INTERACTIONS],
  });
};

export const useDeleteDealInteractionMutation = () => {
  return useMutation(MUTATIONS.DELETE_DEAL_INTERACTION, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Interazione eliminata con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    notifyOnNetworkStatusChange: true,
    refetchQueries: [QUERIES.GET_DEAL_INTERACTIONS],
  });
};

export const useEditDealInteractionMutation = () => {
  return useMutation(MUTATIONS.EDIT_DEAL_INTERACTION, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Interazione modificata con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_INTERACTIONS],
  });
};

export const useAddDealConsultantAssistantMutation = () => {
  return useMutation(MUTATIONS.ADD_DEAL_CONSULTANT_ASSISTANT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Consigliere aggiunto con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_CONSULTANT_ASSISTANTS],
  });
};

export const useRemoveDealConsultantAssistantMutation = () => {
  return useMutation(MUTATIONS.REMOVE_DEAL_CONSULTANT_ASSISTANT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Consigliere rimosso con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_CONSULTANT_ASSISTANTS],
  });
};

export const useUploadDocumentFileMutation = () => {
  return useMutation(MUTATIONS.UPLOAD_DOCUMENT_FILE, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Il file è stato caricato con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_DOCUMENTS],
  });
};

export const useEditDealMutation = () => {
  return useMutation(MUTATIONS.EDIT_DEAL, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Il progetto è stato modificato con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL],
  });
};

export const useUploadDocumentLinkMutation = () => {
  return useMutation(MUTATIONS.UPLOAD_DOCUMENT_LINK, {
    onCompleted: (response) => {
      if (response) {
        toast.success(' Il file è stato caricato con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_DOCUMENTS],
  });
};

export const useRequestDealReportAsClientMutation = (input) => {
  return useMutation(MUTATIONS.REQUEST_DEAL_REPORT_AS_CLIENT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Report aggiunto con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.requestDealReportAsClient) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CLIENT,
        variables: { input },
      });

      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CLIENT,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsClient: {
              ...dealReports?.getDealReportsWithCountAsClient,
              count: dealReports?.getDealReportsWithCountAsClient?.count + 1,
              dealReports: [
                ...(dealReports?.getDealReportsWithCountAsClient?.dealReports ||
                  []),
                data?.requestDealReportAsClient,
              ],
            },
          },
        });
      }
    },
  });
};

export const useUpdateDealReportStatusAsConsultantMutation = (input) => {
  return useMutation(MUTATIONS.UPDATE_DEAL_REPORTS_STATUS_AS_CONSULTANT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Stato modificata con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.updateDealReportStatusAsConsultant) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsConsultant?.dealReports?.map(
          (report) =>
            report.id === data?.updateDealReportStatusAsConsultant?.id
              ? {
                  ...report,
                  status: data?.updateDealReportStatusAsConsultant?.status,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsConsultant: {
              ...dealReports?.getDealReportsWithCountAsConsultant,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};

export const useIssueDealReportAsConsultantMutation = (input) => {
  return useMutation(MUTATIONS.ISSUE_DEAL_REPORT_AS_CONSULTANT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documento caricato correttamente');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.issueDealReportAsConsultant) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsConsultant?.dealReports?.map(
          (report) =>
            report.id === data?.issueDealReportAsConsultant?.id
              ? {
                  ...data?.issueDealReportAsConsultant,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsConsultant: {
              ...dealReports?.getDealReportsWithCountAsConsultant,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};

export const useRessueDealReportAsConsultantMutation = (input) => {
  return useMutation(MUTATIONS.REISSUE_DEAL_REPORT_AS_CONSULTANT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documento caricato correttamente');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.reissueDealReportAsConsultant) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsConsultant?.dealReports?.map(
          (report) =>
            report.id === data?.reissueDealReportAsConsultant?.id
              ? {
                  ...data?.reissueDealReportAsConsultant,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsConsultant: {
              ...dealReports?.getDealReportsWithCountAsConsultant,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};

export const useUpdateDealReportStatusAsAdminMutation = (input) => {
  return useMutation(MUTATIONS.UPDATE_DEAL_REPORTS_STATUS_AS_ADMIN, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Stato modificata con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.updateDealReportStatusAsAdmin) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsAdmin?.dealReports?.map(
          (report) =>
            report.id === data?.updateDealReportStatusAsAdmin?.id
              ? {
                  ...report,
                  status: data?.updateDealReportStatusAsAdmin?.status,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsAdmin: {
              ...dealReports?.getDealReportsWithCountAsAdmin,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};
export const useUpdateStatusInDealDocumentMutation = (input) => {
  return useMutation(MUTATIONS.UPDATE_STATUS_IN_DEAL_DOCUMENT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Stato modificata con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useIssueDealReportAsAdminMutation = (input) => {
  return useMutation(MUTATIONS.ISSUE_DEAL_REPORT_AS_ADMIN, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documento caricato correttamente');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.issueDealReportAsAdmin) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsAdmin?.dealReports?.map(
          (report) =>
            report.id === data?.issueDealReportAsAdmin?.id
              ? {
                  ...data?.issueDealReportAsAdmin,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsAdmin: {
              ...dealReports?.getDealReportsWithCountAsAdmin,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};

export const useRessueDealReportAsAdminMutation = (input) => {
  return useMutation(MUTATIONS.REISSUE_DEAL_REPORT_AS_ADMIN, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documento caricato correttamente');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    update(cache, { data }) {
      if (!data?.reissueDealReportAsAdmin) {
        return null;
      }
      const dealReports = cache.readQuery({
        query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
        variables: { input },
      });

      const newDealReports =
        dealReports?.getDealReportsWithCountAsAdmin?.dealReports?.map(
          (report) =>
            report.id === data?.reissueDealReportAsAdmin?.id
              ? {
                  ...data?.reissueDealReportAsAdmin,
                }
              : report,
        );
      if (dealReports) {
        cache.writeQuery({
          query: QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN,
          variables: { input },
          data: {
            ...dealReports,
            getDealReportsWithCountAsAdmin: {
              ...dealReports?.getDealReportsWithCountAsAdmin,
              dealReports: newDealReports,
            },
          },
        });
      }
    },
  });
};

export const useSendDealMessageMutation = (options) => {
  return useMutation(MUTATIONS.SEND_DEAL_MESSAGE, {
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    ...options,
  });
};

export const useReadDealMessageById = () => {
  return useMutation(MUTATIONS.READ_DEAL_MESSAGE_BY_ID, {
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_CHATS],
  });
};

export const useReadLatestDealMessagesByDealChatId = () => {
  return useMutation(MUTATIONS.READ_LATEST_DEAL_MESSAGES_BY_DEAL_CHAT_ID, {
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_DEAL_CHATS],
  });
};

export const useEditDocumentDates = () => {
  return useMutation(MUTATIONS.EDIT_DEAL_DOCUMENT_DATES, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Date modificate con successo');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useCreateDealDocumentMutation = () => {
  return useMutation(MUTATIONS.CREATE_DEAL_DOCUMENT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documenti aggiunta con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_ALL_DEAL_DOCUMENTS_BY_ID],
  });
};

export const useEditDealDocumentMutation = () => {
  return useMutation(MUTATIONS.EDIT_DEAL_DOCUMENT, {
    onCompleted: (response) => {
      if (response) {
        toast.success('Documenti modificata con successo!');
      }
    },
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    refetchQueries: [QUERIES.GET_ALL_DEAL_DOCUMENTS_BY_ID],
  });
};
