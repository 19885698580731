import { Grid, Stack, Typography } from '@mui/material';

import { AuthLayout } from '../../components';
import { LoginForm } from './LoginForm';

export const Login = () => {
  return (
    <AuthLayout>
      <Grid container spacing={3} maxWidth='xs'>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography component='h1' variant='h5'>
              Accedere
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoginForm />
        </Grid>
      </Grid>
    </AuthLayout>
  );
};
