import { gql } from '@apollo/client';

import { DEAL_MESSAGE_FRAGMENT, USER_FRAGMENT } from './fragments';

export const NEW_DEAL_MESSAGE = gql`
  ${DEAL_MESSAGE_FRAGMENT}
  ${USER_FRAGMENT}
  subscription newDealMessage {
    newDealMessage {
      ...dealMessageFragment
      user {
        ...userFragment
      }
    }
  }
`;
