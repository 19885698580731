import { gql } from '@apollo/client';

import {
  COMPANY_FRAGMENT,
  DEAL_CHAT_FRAGMENT,
  DEAL_CONSULTANT_FRAGMENT,
  DEAL_DOCUMENT_FRAGMENT,
  DEAL_FRAGMENT,
  DEAL_INTERACTION_FRAGMENT,
  DEAL_MESSAGE_FRAGMENT,
  DEAL_REPORT_FRAGMENT,
  USER_FRAGMENT,
} from './fragments';

export const SIGN_IN = gql`
  ${USER_FRAGMENT}
  query signin($input: SigninInput!) {
    signin(input: $input) {
      user {
        ...userFragment
      }
      accessToken
    }
  }
`;

export const GET_CURRENT_USER = gql`
  ${USER_FRAGMENT}
  query getCurrentUser {
    getCurrentUser {
      ...userFragment
      companyInWhichAsClient {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  query getUsers($input: GetUsersOptionsInput!) {
    getUsers(input: $input) {
      users {
        ...userFragment
        role
        cellPhone
        landlinePhone
        createdAt
        companyInWhichAsClient {
          ...companyFragment
        }
      }
      count
    }
  }
`;

export const GET_USER_QUERY = gql`
  ${USER_FRAGMENT}
  query getUserById($id: Int!) {
    getUserById(id: $id) {
      ...userFragment
      cellPhone
      landlinePhone
    }
  }
`;

export const GET_COMPANIES_AS_ADMIN = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${DEAL_FRAGMENT}
  query getCompaniesAsAdmin($input: GetCompaniesAsAdminInput!) {
    getCompaniesAsAdmin(input: $input) {
      companies {
        ...companyFragment
        client {
          ...userFragment
        }
        leadConsultant {
          ...userFragment
        }
        deals {
          ...dealFragment
        }
      }
      count
    }
  }
`;

export const GET_COMPANIES_AS_ADMIN_SELECTOR = gql`
  query getCompaniesAsAdminSelector {
    getCompaniesAsAdmin(
      input: { limit: 100, sortByField: createdAt, orderBy: asc }
    ) {
      companies {
        id
        name
      }
      count
    }
  }
`;

export const GET_COMPANIES_AS_CONSULTANT_SELECTOR = gql`
  query getCompaniesAsConsultantSelector {
    getCompaniesAsConsultant(
      input: { limit: 100, sortByField: createdAt, orderBy: asc }
    ) {
      companies {
        id
        name
        leadConsultantId
      }
      count
    }
  }
`;

export const GET_COMPANIES_AS_CONSULTANT = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${DEAL_FRAGMENT}
  query getCompaniesAsConsultant($input: GetCompaniesAsConsultantInput!) {
    getCompaniesAsConsultant(input: $input) {
      companies {
        ...companyFragment
        client {
          ...userFragment
        }
        leadConsultant {
          ...userFragment
        }
        deals {
          ...dealFragment
        }
      }
      count
    }
  }
`;

export const GET_COMPANY_BY_ID_QUERY = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  query getCompanyById($id: Int!) {
    getCompanyById(id: $id) {
      ...companyFragment
      client {
        ...userFragment
      }
      leadConsultant {
        id
        ...userFragment
      }
      deals {
        id
      }
    }
  }
`;

export const GET_DEALS = gql`
  ${DEAL_FRAGMENT}
  ${DEAL_DOCUMENT_FRAGMENT}
  query getDeals($input: GetDealsOptionsInput!) {
    getDeals(input: $input) {
      deals {
        ...dealFragment
        dealChat {
          id
        }
        dealDocuments {
          ...dealDocumentFragment
        }
      }
      count
    }
  }
`;

export const GET_DEAL = gql`
  ${DEAL_FRAGMENT}
  ${DEAL_DOCUMENT_FRAGMENT}
  query getDeal($id: Int!) {
    getDealById(id: $id) {
      ...dealFragment
      dealDocuments {
        ...dealDocumentFragment
      }
    }
  }
`;

export const GET_DEAL_CONSULTANT_ASSISTANTS = gql`
  ${DEAL_CONSULTANT_FRAGMENT}
  query dealConsultantAssistants($id: Int!) {
    getDealById(id: $id) {
      id
      dealConsultantAssistants {
        ...dealConsultantFragment
      }
    }
  }
`;

export const GET_ALL_CONSULTANTS = gql`
  ${USER_FRAGMENT}
  query getAllConsultantsExeptCurrentOneAsConsultant {
    getAllConsultantsExeptCurrentOneAsConsultant {
      ...userFragment
    }
  }
`;

export const GET_DEAL_DOCUMENTS = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  query getDealDocuments($id: Int!) {
    getDealById(id: $id) {
      dealDocuments {
        ...dealDocumentFragment
      }
    }
  }
`;
export const GET_ALL_DEAL_DOCUMENTS_BY_ID = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  query getAllDealDocumentsByDealId($dealId: Int!) {
    getAllDealDocumentsByDealId(dealId: $dealId) {
      ...dealDocumentFragment
    }
  }
`;

export const GET_DEAL_INTERACTIONS = gql`
  ${DEAL_INTERACTION_FRAGMENT}
  query getDealInteractionLogByDealId($id: Int!, $offset: Int!, $limit: Int!) {
    getDealInteractionLogByDealId(
      input: { dealId: $id, offset: $offset, limit: $limit }
    ) {
      dealInteractionLogs {
        ...dealInteractionFragment
      }
      count
    }
  }
`;

export const GET_DEAL_TYPES = gql`
  query getDealTypes {
    getDealTypes(input: { limit: 20 }) {
      dealTypes {
        id
        name
      }
    }
  }
`;

export const GET_LAST_DEAL_REPORTS_AS_CLIENT = gql`
  ${COMPANY_FRAGMENT}
  query getLastDealReportsAsClient($limit: Int!) {
    getLastDealReportsAsClient(limit: $limit) {
      id
      fileName
      status
      type
      createdAt
      issuedAt
      deal {
        id
        name
        company {
          ...companyFragment
        }
      }
    }
  }
`;

export const GET_LAST_DEAL_REPORTS_AS_CONSULTANT = gql`
  ${COMPANY_FRAGMENT}
  query getLastDealReportsAsConsultant(
    $input: GetLastDealReportsAsConsultantInput!
  ) {
    getLastDealReportsAsConsultant(input: $input) {
      id
      fileName
      status
      type
      createdAt
      issuedAt
      deal {
        id
        name
        company {
          ...companyFragment
        }
      }
    }
  }
`;

export const GET_LAST_DEAL_REPORTS_AS_ADMIN = gql`
  ${COMPANY_FRAGMENT}
  query getLastDealReportsAsAdmin($input: GetLastDealReportsAsAdminInput!) {
    getLastDealReportsAsAdmin(input: $input) {
      id
      fileName
      status
      type
      createdAt
      issuedAt
      deal {
        id
        name
        company {
          ...companyFragment
        }
      }
    }
  }
`;

export const GET_CLIENTS_AS_CONSULTANT = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  query getClientsAsConsultant {
    getClientsAsConsultant {
      ...userFragment
      role
      cellPhone
      landlinePhone
      createdAt
      companyInWhichAsClient {
        ...companyFragment
      }
    }
  }
`;

export const GET_DEAL_REPORTS_WITH_COUNT_AS_CLIENT = gql`
  ${DEAL_REPORT_FRAGMENT}
  query getDealReportsWithCountAsClient(
    $input: GetDealReportsWithCountAsClientInput!
  ) {
    getDealReportsWithCountAsClient(input: $input) {
      dealReports {
        ...dealReportFragment
      }
      count
    }
  }
`;
export const GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT = gql`
  ${DEAL_REPORT_FRAGMENT}
  query getDealReportsWithCountAsConsultant(
    $input: GetDealReportsWithCountInput!
  ) {
    getDealReportsWithCountAsConsultant(input: $input) {
      dealReports {
        ...dealReportFragment
      }
      count
    }
  }
`;
export const GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN = gql`
  ${DEAL_REPORT_FRAGMENT}
  query getDealReportsWithCountAsAdmin($input: GetDealReportsWithCountInput!) {
    getDealReportsWithCountAsAdmin(input: $input) {
      dealReports {
        ...dealReportFragment
      }
      count
    }
  }
`;

export const GET_DEAL_CHATS = gql`
  ${DEAL_FRAGMENT}
  ${DEAL_MESSAGE_FRAGMENT}
  ${USER_FRAGMENT}
  ${DEAL_CHAT_FRAGMENT}
  query getDealChats(
    $pagination: PaginationInput!
    $dealMessagesWithCountPagination: PaginationInput!
    $participantsWithCountPagination: PaginationInput!
  ) {
    getDealChats(pagination: $pagination) {
      count
      dealChats {
        ...dealChatFragment
        deal {
          ...dealFragment
        }
        dealMessagesWithCount(pagination: $dealMessagesWithCountPagination) {
          count
          dealMessages {
            ...dealMessageFragment
          }
        }
        participantsWithCount(pagination: $participantsWithCountPagination) {
          count
          users {
            ...userFragment
          }
        }
      }
    }
  }
`;

export const GET_DEAL_CHAT_BY_ID = gql`
  ${DEAL_FRAGMENT}
  ${DEAL_MESSAGE_FRAGMENT}
  ${USER_FRAGMENT}
  ${DEAL_CHAT_FRAGMENT}
  query getDealChatById(
    $dealChatId: Int!
    $pagination: PaginationInput!
    $participantsWithCountPagination2: PaginationInput!
  ) {
    getDealChatById(dealChatId: $dealChatId) {
      ...dealChatFragment
      deal {
        ...dealFragment
      }
      dealMessagesWithCount(pagination: $pagination) {
        count
        dealMessages {
          ...dealMessageFragment
          user {
            ...userFragment
          }
        }
      }
      participantsWithCount(pagination: $participantsWithCountPagination2) {
        count
        users {
          ...userFragment
        }
      }
    }
  }
`;

export const GET_ONE_DEAL_DOCUMENT_BY_ID = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  query GetOneDealDocumentById($id: Int!) {
    getOneDealDocumentById(id: $id) {
      dealId
      ...dealDocumentFragment
    }
  }
`;
