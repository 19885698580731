import { Grid, Stack, Typography } from '@mui/material';
import { Link, Navigate, useSearchParams } from 'react-router-dom';

import { AuthLayout } from '../../components';
import { ROUTES } from '../../constants/constants';
import { NewPasswordForm } from './NewPasswordForm';

export const NewPassword = () => {
  const [searchParams] = useSearchParams();

  if (!searchParams.get('token')) {
    return <Navigate to={ROUTES.login} />;
  }
  return (
    <AuthLayout>
      <Grid container spacing={3} maxWidth='xs'>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography component='h1' variant='h5'>
              Crea una nuova password
            </Typography>
            <Typography
              component={Link}
              to={ROUTES.login}
              variant='body2'
              sx={{ textDecoration: 'none' }}
              color='primary'
            >
              Torna al login
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <NewPasswordForm />
        </Grid>
      </Grid>
    </AuthLayout>
  );
};
