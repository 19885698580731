import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useDebounce } from '../hooks';

const SearchContext = createContext();
const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } =
    useDebounce(searchValue);

  const { pathname } = useLocation();

  const initPathRef = useRef('');

  useEffect(() => {
    if (!initPathRef.current) {
      initPathRef.current = pathname;
    } else if (initPathRef.current !== pathname) {
      setSearchValue('');
    }
  }, [pathname]);

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        debouncedSearchValue,
        isDebouncing,
        setSearchValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SearchProvider, useSearchContext };
