import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';

export const LogoSection = ({ to, isLink }) => {
  return isLink ? (
    <ButtonBase disableRipple component={Link} to={!to ? ROUTES.home : to}>
      <img src={'kps-logo.png'} alt='logo' />
    </ButtonBase>
  ) : (
    <img style={{ width: '17%' }} src={'kps-logo.png'} alt='logo' />
  );
};

LogoSection.propTypes = {
  to: PropTypes.string,
  isLink: PropTypes.bool,
};
