import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainMessageWrapper = styled(Box)(({ isyou }) => ({
  display: 'flex',
  flexDirection: isyou && 'row-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px',
}));

export const MessageTextWrapper = styled(Typography)(({ isyou }) => ({
  wordBreak: 'break-all',
  whiteSpace: 'normal',
  ...(isyou && { alignSelf: 'flex-end' }),
}));
export const TypographyWrapper = styled(Typography)(({ isyou }) => ({
  ...(isyou && { alignSelf: 'flex-end' }),
}));
