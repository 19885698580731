import { Search } from '@mui/icons-material';
import { InputAdornment, InputBase, Paper } from '@mui/material';
import React from 'react';

import { useSearchContext } from '../../contexts';

export const SearchBar = () => {
  const { searchValue, setSearchValue } = useSearchContext();

  const onInputChange = (val) => setSearchValue(val.target.value);

  return (
    <Paper sx={{ height: '36px', display: 'flex', padding: '0 12px' }}>
      <InputBase
        value={searchValue}
        onChange={onInputChange}
        inputProps={{
          style: {
            height: '34px',
            padding: '0',
          },
        }}
        placeholder='ricerca...'
        endAdornment={
          <InputAdornment position='end'>
            <Search />
          </InputAdornment>
        }
      />
    </Paper>
  );
};
