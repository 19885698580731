import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormHelperText,
  OutlinedInput,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { ChatAvatar, ChatMessage, RequestHandler } from '../../components';
import { useAuthContext, useChatContext } from '../../contexts';
import {
  useReadDealMessageById,
  useReadLatestDealMessagesByDealChatId,
} from '../../hooks';
import { useChat } from '../../hooks/useChat';

export const CommunicationChat = ({ user }) => {
  const { isUserAdmin } = useAuthContext();
  const { chatId, newDealMessageData } = useChatContext();
  const chatRef = useRef(null);

  const {
    messages,
    addMessage,
    loading,
    sendMessageLoading,
    chatData,
    setMessages,
  } = useChat(chatId, chatRef);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
  });

  const [readLatestDealMessagesByDealChatIdMutation] =
    useReadLatestDealMessagesByDealChatId();

  const [readDealMessageByIdMutation] = useReadDealMessageById();

  const readMessageById = useCallback(() => {
    if (newDealMessageData?.newDealMessage?.id)
      readDealMessageByIdMutation({
        variables: {
          dealMessageId: newDealMessageData?.newDealMessage?.id,
        },
      });
  }, [newDealMessageData?.newDealMessage?.id, readDealMessageByIdMutation]);

  const readChat = useCallback(() => {
    readLatestDealMessagesByDealChatIdMutation({
      variables: {
        dealChatId: chatId,
      },
    });
  }, [chatId, readLatestDealMessagesByDealChatIdMutation]);

  useEffect(() => {
    if (
      messages?.length > 0 &&
      chatData?.getDealChatById?.unreadDealMessagesCount &&
      chatId === newDealMessageData?.newDealMessage?.dealChatId
    )
      readMessageById();
  }, [readMessageById, chatId]);

  useEffect(() => {
    if (!newDealMessageData) return;
    setMessages((prev) => [{ ...newDealMessageData.newDealMessage }, ...prev]);
  }, [newDealMessageData]);

  useEffect(() => {
    if (chatRef?.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, [messages, chatRef?.current]);

  useEffect(() => {
    if (
      messages?.length > 0 &&
      chatData?.getDealChatById?.unreadDealMessagesCount
    ) {
      readChat();
    }
  }, [chatId, readChat, messages?.length]);

  const onSubmit = async ({ message }) => {
    addMessage(message);
    reset();
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <ChatAvatar
          firstName={user.firstName}
          lastName={user.lastName}
          uri={user.uri}
        />
        <Divider sx={{ marginTop: '24px' }} />

        <Stack
          pt='30px'
          spacing={2}
          justifyContent='space-between'
          sx={{
            height: '100%',
          }}
        >
          <Box
            sx={{
              overflowY: 'auto',
              padding: '5px',
              height: isUserAdmin
                ? 'calc(100vh - 202px)'
                : 'calc(100vh - 334px)',
            }}
            ref={chatRef}
          >
            <RequestHandler loading={loading}>
              <Stack spacing={1}>
                {messages
                  ?.map((message) => (
                    <ChatMessage
                      key={message.id}
                      user={message.user}
                      messageText={message.text}
                      date={message.createdAt}
                      isyou={user.id === message.user.id}
                    />
                  ))
                  .reverse()}
              </Stack>
            </RequestHandler>
          </Box>
          {!isUserAdmin ? (
            <Box
              sx={{
                display: 'flex',
              }}
              component={'form'}
              onSubmit={handleSubmit(onSubmit)}
            >
              <OutlinedInput
                id='message'
                type='text'
                placeholder='Enter message'
                fullWidth
                autoComplete='message'
                error={Boolean(errors.message)}
                {...register('message')}
              />
              {errors.firstName && (
                <FormHelperText
                  error
                  id='standard-weight-helper-text-first-name'
                >
                  {errors.message.message.toString()}
                </FormHelperText>
              )}

              <Button
                sx={{ marginLeft: '12px' }}
                type='submit'
                variant='contained'
                disabled={!watch('message') || sendMessageLoading}
                endIcon={
                  !sendMessageLoading ? <SendIcon /> : <CircularProgress />
                }
              >
                Send
              </Button>
            </Box>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};

CommunicationChat.propTypes = {
  user: PropTypes.object,
};
