import { Edit, KeyboardArrowRight } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import { getStatusDeals } from '../../utils';

export const DashboardProject = ({ projects, isClient, onClick }) => {
  return (
    <Card>
      <CardContent>
        <Typography mb={3}>Ultime Pratiche</Typography>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Pratica</TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Data Inizio
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Scadenza
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Stato
                </TableCell>
                {!isClient ? (
                  <TableCell sx={{ fontWeight: 700 }} align='center'>
                    Details
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{ fontWeight: 'medium' }}
                    align='center'
                  ></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {projects?.map((project) => (
                <TableRow
                  key={project?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{project?.name}</TableCell>
                  <TableCell align='center'>
                    {project?.createdAt
                      ? format(
                          new Date(parseInt(project?.createdAt)),
                          'dd.MM.yyyy',
                        )
                      : null}
                  </TableCell>
                  <TableCell align='center'>
                    {project?.submittedAt
                      ? format(
                          new Date(parseInt(project?.submittedAt)),
                          'dd.MM.yyyy',
                        )
                      : null}
                  </TableCell>
                  <TableCell align='center'>
                    <Chip
                      label={getStatusDeals(project?.status).label}
                      color={getStatusDeals(project?.status).color}
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <IconButton
                      onClick={() => {
                        onClick(project.id, project?.dealChat?.id);
                      }}
                    >
                      {!isClient ? <Edit /> : <KeyboardArrowRight />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

DashboardProject.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      status: PropTypes.string,
      submittedAt: PropTypes.string,
      approvedAt: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  isClient: PropTypes.boolean,
  onClick: PropTypes.func,
};
