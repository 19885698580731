import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Loader, SideBar } from '../components';
import { ROUTES } from '../constants/constants';
import { useAuthContext } from '../contexts';
import { useGetCurrentUserLazyQuery } from '../hooks';
import { NotFound } from '../pages';
import { routes } from './routes';

export const RouterWrapper = () => {
  const [getCurrentUser, { loading }] = useGetCurrentUserLazyQuery();

  const { isAuth, hasToken, user } = useAuthContext();

  useEffect(() => {
    if (hasToken && !isAuth) {
      getCurrentUser();
    }
  }, [hasToken]);

  if (loading || (!isAuth && hasToken)) {
    return <Loader />;
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectedRoute {...route} isAuth={isAuth} user={user} />}
        />
      ))}
      <Route key={'NotFound'} path='*' element={<NotFound />} />
    </Routes>
  );
};

const ProtectedRoute = ({
  element: Element,
  path,
  roles,
  user,
  isAuth = true,
}) => {
  // исключение для страниц не требующих авторизации
  if (authRoutCheck(path)) {
    if (isAuth) return <Navigate to={ROUTES.home} />;
    return <Element />;
  }

  if (user?.role && !roles.includes(user?.role))
    return <Navigate to={ROUTES.home} />;

  if (isAuth) {
    return (
      <Box>
        <SideBar>
          <Element />
        </SideBar>
      </Box>
    );
  }

  return <Navigate to={ROUTES.login} />;
};

ProtectedRoute.propTypes = {
  path: PropTypes.node,
  element: PropTypes.any,
  roles: PropTypes.array,
  user: PropTypes.object,
  isAuth: PropTypes.bool,
  isAccessAlowed: PropTypes.bool,
};

const authRoutCheck = (path) => {
  const authRout = [ROUTES.login, ROUTES.forgotPassword, ROUTES.newPassword];
  return authRout.includes(path);
};
