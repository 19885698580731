import * as yup from 'yup';

import { messages as M } from '../messages';

export const addDocumentSchema = yup.object().shape(
  {
    file: yup.mixed().when('externalLink', {
      is: (val) => {
        return !val;
      },
      then: yup.mixed().required(M.REQUIRE_MESSAGE),
      otherwise: yup.mixed(),
    }),
    externalLink: yup.string().when('file', {
      is: (val) => {
        return !val;
      },
      then: yup.string().nullable().required(M.REQUIRE_MESSAGE),
      otherwise: yup
        .string()
        .nullable()
        .transform((value) => (value ? value : null)),
    }),
    startedAt: yup.string().nullable().required(M.REQUIRE_MESSAGE),
    endedAt: yup.string().nullable().required(M.REQUIRE_MESSAGE),
  },
  ['file', 'externalLink'],
);
