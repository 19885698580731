import {
  Business,
  CalendarToday,
  ChatBubbleOutline,
  Group,
  MailOutline,
  PeopleAlt,
  ShoppingCart,
} from '@mui/icons-material';

export const API_HOST = process.env.REACT_APP_API_URL;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const SOCKET_HOST = process.env.REACT_APP_API_SOCKET_URL;

export const ROUTES = {
  home: '/',
  devPage: '/devPage',
  login: '/login',
  forgotPassword: '/forgotPassword',
  newPassword: '/password-reset',
  projects: '/projects',
  project: '/project',
  editProject: '/project/:projectId/:projectChatId',
  communication: '/communication',
  documents: '/documents',
  document: '/document',
  editDocument: '/document/:id',
  report: '/report',
  users: '/users',
  editUser: '/user/:id',
  user: '/user',
  companies: '/companies',
  company: '/company',
  editCompany: '/company/:id',
  searchScreens() {
    return [
      this.projects,
      this.communication,
      this.documents,
      this.report,
      this.users,
      this.companies,
    ];
  },
  isSearchScreen(name) {
    return this.searchScreens().includes(name);
  },
};

export const TYPE_MODAL = {
  TEST: 'test',
};

export const drawerWidth = 240;

export const navLinkItems = [
  {
    text: 'Dashboard',
    icon: <CalendarToday fontSize='small' />,
    path: ROUTES.home,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    text: 'Pratiche',
    icon: <ChatBubbleOutline fontSize='small' />,
    path: ROUTES.projects,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    text: 'Comunicazioni',
    icon: <ShoppingCart fontSize='small' />,
    path: ROUTES.communication,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    text: 'Documenti',
    icon: <PeopleAlt fontSize='small' />,
    path: ROUTES.documents,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    text: 'Report',
    icon: <MailOutline fontSize='small' />,
    path: ROUTES.report,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    text: 'Utenti',
    icon: <Group fontSize='small' />,
    path: ROUTES.users,
    roles: ['admin', 'consultant'],
  },
  {
    text: 'Aziende',
    icon: <Business fontSize='small' />,
    path: ROUTES.companies,
    roles: ['admin', 'consultant'],
  },
];

export const ERROR_MESSAGES = {
  WrongEmailOrPasswordError: 'Email o password errate',
  'User not found': 'Utente non trovato',
  'Company must have owner': "L'azienda deve avere un proprietario",
};

export const LIMIT_ITEM_PAGE = 10;

export const LIMIT_DEAL_INTERACTIONS_PAGE = 4;

export const ROLES = {
  admin: 'Amministratore',
  consultant: 'Consulente',
  client: 'Cliente',
};

export const COMPANY_SIZE = {
  micro: 'Micro',
  small: 'Piccola',
  medium: 'Media',
  large: 'Grande',
};

export const DEAL_REPORT_TYPE = {
  minimum: 'minimum',
  cr: 'cr',
  xRating: 'xRating',
  mcc: 'mcc',
};

export const DEAL_INTERACTION_TYPES = {
  call: 'Chiamata',
  email: 'E-mail',
  messenger: 'Messaggero',
};

export const DEAL_REPORT_STATUS = {
  requested: 'Richiesto',
  workInProgress: 'Lavori in corso',
  docsNeeded: 'Documenti necessari',
  ready: 'Pronto',
};

export const DOCUMENT_STATUS = {
  missing: 'Mancante',
  incomplete: 'Incompleto',
  outdated: 'Obsoleto',
  valid: 'Valido',
};
