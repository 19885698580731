import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  LIMIT_DEAL_INTERACTIONS_PAGE,
  LIMIT_ITEM_PAGE,
} from '../constants/constants';
import { useAuthContext, useSearchContext } from '../contexts';
import * as QUERIES from '../graphql/queries';
import { useFilter } from './useFilter';

export const useSigninLazyQuery = () => {
  const { signIn } = useAuthContext();

  return useLazyQuery(QUERIES.SIGN_IN, {
    onCompleted: (response) => {
      if (response?.signin) {
        localStorage.setItem('token', response.signin?.accessToken);
        signIn(response.signin?.user);
      }
    },
  });
};

export const useGetCurrentUserLazyQuery = () => {
  const { signIn, signOut } = useAuthContext();

  return useLazyQuery(QUERIES.GET_CURRENT_USER, {
    onCompleted: (response) => {
      if (response?.getCurrentUser) {
        signIn(response?.getCurrentUser);
      }
    },
    onError: (error) => {
      if (error) {
        signOut();
      }
    },
  });
};

export const useGetUsersQuery = ({ pagePagination = 1, isLimit = true }) => {
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_USERS, {
    skip: user.role !== 'admin',
    variables: {
      input: {
        offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
        limit: isLimit ? LIMIT_ITEM_PAGE : 0,
        search: searchValue,
        orderByCreatedAt: filters.order || 'desc',
      },
    },

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetConsultantsQuery = () => {
  return useQuery(QUERIES.GET_USERS, {
    variables: {
      input: {
        offset: 0,
        limit: 0,
        filters: { role: 'consultant' },
      },
    },

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
export const useGetClientsQuery = ({ ...options }) => {
  return useQuery(QUERIES.GET_USERS, {
    variables: {
      input: {
        offset: 0,
        limit: 0,
        filters: { role: 'client' },
      },
    },
    ...options,

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetUserByIdQuery = () => {
  const params = useParams();
  const id = Number(params?.id);

  return useQuery(QUERIES.GET_USER_QUERY, {
    skip: !id,
    variables: {
      ...({ id } || undefined),
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetCompaniesAsAdminQuery = ({
  pagePagination = 1,
  isLimit = true,
  ...options
}) => {
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_COMPANIES_AS_ADMIN, {
    skip: user.role !== 'admin',
    variables: {
      input: {
        offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
        limit: isLimit ? LIMIT_ITEM_PAGE : 0,
        searchByName: searchValue,
        sortByField: filters.orderBy || 'createdAt',
        orderBy: filters.order || 'desc',
      },
    },
    ...options,

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
export const useGetCompaniesAsConsultantQuery = ({
  pagePagination = 1,
  isLimit = true,
  ...options
}) => {
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_COMPANIES_AS_CONSULTANT, {
    skip: user.role !== 'consultant',
    variables: {
      input: {
        offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
        limit: isLimit ? LIMIT_ITEM_PAGE : 0,
        searchByName: searchValue,
        sortByField: filters.orderBy || 'createdAt',
        orderBy: filters.order || 'desc',
      },
    },
    ...options,

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetCompanyByIdQuery = (companyId) => {
  const params = useParams();
  const id = Number(params?.id);

  return useQuery(QUERIES.GET_COMPANY_BY_ID_QUERY, {
    skip: !id,
    variables: {
      ...({ id: companyId ? companyId : id } || undefined),
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealsQuery = ({
  pagePagination = 1,
  limit,
  withSort = true,
  clientId,
  companyId,
  ...options
}) => {
  const { searchValue } = useSearchContext();
  const { filters } = useFilter();

  return useQuery(QUERIES.GET_DEALS, {
    variables: {
      input: {
        offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
        limit: limit || LIMIT_ITEM_PAGE,
        search: searchValue,
        ...(clientId && { filters: { clientId } }),
        ...(companyId && { filters: { companyId } }),
        ...(withSort && { sort: filters }),
      },
    },
    ...options,

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealQuery = () => {
  const params = useParams();
  const id = params?.projectId;

  return useQuery(QUERIES.GET_DEAL, {
    skip: !id,
    variables: {
      ...({ id: +id } || undefined),
    },

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetAllDealDocumentByIdLazyQuery = () => {
  return useLazyQuery(QUERIES.GET_ALL_DEAL_DOCUMENTS_BY_ID, {
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealAssistantsQuery = () => {
  const params = useParams();
  const id = params?.projectId;

  return useQuery(QUERIES.GET_DEAL_CONSULTANT_ASSISTANTS, {
    skip: !id,
    variables: {
      ...({ id: +id } || undefined),
    },

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetAllConsultantsLazyQuery = () => {
  return useLazyQuery(QUERIES.GET_ALL_CONSULTANTS, {
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealDocumentsQuery = () => {
  const params = useParams();
  const id = params?.projectId;

  return useQuery(QUERIES.GET_DEAL_DOCUMENTS, {
    skip: !id,
    variables: {
      ...({ id: +id } || undefined),
    },

    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealInteractionsQuery = ({ interactionsPage = 1 }) => {
  const params = useParams();
  const id = params?.projectId;

  return useQuery(QUERIES.GET_DEAL_INTERACTIONS, {
    skip: !id,
    variables: {
      ...({ id: +id } || undefined),
      offset: (interactionsPage - 1) * LIMIT_DEAL_INTERACTIONS_PAGE,
      limit: LIMIT_DEAL_INTERACTIONS_PAGE,
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealTypesLazyQuery = () => {
  return useLazyQuery(QUERIES.GET_DEAL_TYPES);
};

export const useGetCompaniesAsAdminSelectorLazyQuery = () => {
  return useLazyQuery(QUERIES.GET_COMPANIES_AS_ADMIN_SELECTOR);
};

export const useGetCompaniesAsConsultantSelectorLazyQuery = () => {
  return useLazyQuery(QUERIES.GET_COMPANIES_AS_CONSULTANT_SELECTOR);
};

export const useGetLastDealReportsAsClient = (options) => {
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_LAST_DEAL_REPORTS_AS_CLIENT, {
    skip: user.role !== 'client',
    variables: { limit: 5 },
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetLastDealReportsAsConsultant = ({ clientId, ...options }) => {
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_LAST_DEAL_REPORTS_AS_CONSULTANT, {
    skip: user.role !== 'consultant',
    variables: { input: { limit: 5, ...(clientId && { clientId }) } },
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
export const useGetLastDealReportsAsAdmin = ({ clientId, ...options }) => {
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_LAST_DEAL_REPORTS_AS_ADMIN, {
    skip: user.role !== 'admin',
    variables: { input: { limit: 5, ...(clientId && { clientId }) } },
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetClientsAsConsultantQuery = (options) => {
  const { user } = useAuthContext();

  return useQuery(QUERIES.GET_CLIENTS_AS_CONSULTANT, {
    skip: user.role !== 'consultant',
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useGetDealReportsWithCountAsClientLazyQuery = (options) => {
  return useLazyQuery(QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CLIENT, {
    ...options,
  });
};
export const useGetDealReportsWithCountAsConsultantLazyQuery = (options) => {
  return useLazyQuery(QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_CONSULTANT, {
    ...options,
  });
};
export const useGetDealReportsWithCountAsAdminLazyQuery = (options) => {
  return useLazyQuery(QUERIES.GET_DEAL_REPORTS_WITH_COUNT_AS_ADMIN, {
    ...options,
  });
};

export const useGetDealChatsQuery = ({ pagePagination = 1 }) => {
  return useQuery(QUERIES.GET_DEAL_CHATS, {
    variables: {
      pagination: {
        offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
        limit: LIMIT_ITEM_PAGE,
      },
      dealMessagesWithCountPagination: {
        limit: 10,
        offset: 0,
      },
      participantsWithCountPagination: {
        limit: 10,
        offset: 0,
      },
    },
  });
};

export const useGetDealChatByIdLazyQuery = ({ chatId }) => {
  return useLazyQuery(QUERIES.GET_DEAL_CHAT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        limit: 0,
        offset: 0,
      },
      participantsWithCountPagination2: {
        limit: 10,
        offset: 0,
      },
      dealChatId: chatId,
    },
  });
};

export const useGetOneDealDocumentByIdQuery = () => {
  const params = useParams();
  const id = Number(params?.id);

  return useQuery(QUERIES.GET_ONE_DEAL_DOCUMENT_BY_ID, {
    skip: !id,
    variables: {
      ...({ id } || undefined),
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
