import {
  Box,
  Card,
  CardContent,
  MenuItem,
  MenuList,
  Pagination,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ChatAvatar, RequestHandler } from '../../components';
import { LIMIT_ITEM_PAGE } from '../../constants/constants';
import { useChatContext } from '../../contexts';

export const CommunicationChats = ({ user }) => {
  const [chats, setChats] = useState([]);
  const {
    chatId,
    setChatId,
    chatsData,
    pagePagination,
    setPagePagination,
    chatsLoading,
  } = useChatContext();

  useEffect(() => {
    if (!chatsData) return;
    setChats([...chatsData.getDealChats.dealChats]);
  }, [chatsData]);

  const getSortChats = (chats) => {
    return chats.sort(
      (a, b) => b.unreadDealMessagesCount - a.unreadDealMessagesCount || [],
    );
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Box
          sx={{
            marginBottom: '24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ChatAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            uri={user.uri}
          />
        </Box>
        <Typography sx={{ marginTop: '24px' }}>Chat Practica</Typography>
        <RequestHandler loading={chatsLoading}>
          <MenuList>
            {getSortChats(chats).map((dealChat) => (
              <MenuItem
                key={dealChat.id}
                onClick={() => {
                  setChatId(dealChat.id);
                  localStorage.setItem('chatId', dealChat.id);
                }}
                selected={chatId === dealChat.id}
              >
                {`${dealChat.unreadDealMessagesCount} Practiche ${dealChat.dealId}`}
              </MenuItem>
            ))}
          </MenuList>
        </RequestHandler>

        <Box mt={4}>
          <Pagination
            count={
              chatsData?.getDealChats?.count
                ? Math.ceil(chatsData?.getDealChats?.count / LIMIT_ITEM_PAGE)
                : 1
            }
            page={pagePagination}
            onChange={(_, page) => {
              setPagePagination(page);
            }}
            variant='outlined'
            color='primary'
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CommunicationChats.propTypes = {
  user: PropTypes.object,
};
