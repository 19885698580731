import { Add, Edit, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreateProjectModal,
  MainLayout,
  RequestHandler,
  TableSortCell,
} from '../../components';
import { LIMIT_ITEM_PAGE, ROUTES } from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import {
  useCreateDealMutation,
  useFilter,
  useGetCompaniesAsAdminSelectorLazyQuery,
  useGetCompaniesAsConsultantSelectorLazyQuery,
  useGetDealsQuery,
  useGetDealTypesLazyQuery,
} from '../../hooks';
import { getFormattedDate, getStatusDeals } from '../../utils';

export const Projects = () => {
  const [pagePagination, setPagePagination] = useState(1);

  const { isUserClient, isUserAdmin, isUserConsultant, user } =
    useAuthContext();

  const { data: dealsData, loading: isDealsLoading } = useGetDealsQuery({
    pagePagination,
    fetchPolicy: 'network-only',
  });

  const [createDeal] = useCreateDealMutation();

  const [getDealTypes, { data: dealTypesData, loading: isDealTypesLoading }] =
    useGetDealTypesLazyQuery();

  const [
    getCompaniesAsAdmin,
    { data: companiesDataAsAdmin, loading: isCompaniesAsAdminLoading },
  ] = useGetCompaniesAsAdminSelectorLazyQuery();

  const [
    getCompaniesAsConsultant,
    {
      data: companiesDataAsConsultant,
      loading: isCompaniesAsConsultantLoading,
    },
  ] = useGetCompaniesAsConsultantSelectorLazyQuery();

  const getCompaniesArray = () => {
    if (isCompaniesAsAdminLoading || isCompaniesAsConsultantLoading) {
      return [];
    } else if (isUserAdmin) {
      return companiesDataAsAdmin?.getCompaniesAsAdmin?.companies;
    } else if (isUserConsultant) {
      return companiesDataAsConsultant?.getCompaniesAsConsultant?.companies.filter(
        (company) => company.leadConsultantId === user.id,
      );
    } else {
      return [];
    }
  };

  const dealsArray = dealsData?.getDeals?.deals;

  const headCells = [
    {
      label: 'Pratica',
      align: 'left',
    },
    {
      sortId: 'createdAt',
      label: 'Data creazione',
      align: 'left',
    },
    {
      sortId: 'submittedAt',
      label: 'Data Presentazione',
      align: 'left',
    },
    {
      label: 'Importo',
      align: 'left',
    },
    {
      label: 'Stato',
      align: 'left',
    },

    {
      label: 'Dettagli',
      align: 'center',
    },
  ];

  const pageQuantity =
    Math.ceil(dealsData?.getDeals?.count / LIMIT_ITEM_PAGE) || 1;

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const { filters, setFilters } = useFilter();

  const handleClickOpen = async () => {
    setIsOpen(true);
    await getDealTypes();
    if (isUserAdmin) {
      await getCompaniesAsAdmin();
    } else if (isUserConsultant) {
      await getCompaniesAsConsultant();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCreateDeal = async ({ dealType, dealCompany, gross, net }) => {
    await createDeal({
      variables: {
        input: {
          gross,
          net,
          dealTypeId: dealType?.id,
          companyId: dealCompany?.id,
        },
      },
    });
    setIsOpen(false);
  };

  const handleClickEditButton = (projectId, projectChatId) => {
    navigate(`${ROUTES.project}/${projectId}/${projectChatId}`);
  };

  return (
    <MainLayout>
      {isUserClient ? null : (
        <>
          <Button
            sx={{ marginBottom: '24px' }}
            variant='contained'
            startIcon={<Add />}
            onClick={handleClickOpen}
          >
            Aggiungi pratica
          </Button>
          <CreateProjectModal
            onClose={handleClose}
            onSubmit={handleCreateDeal}
            dealTypesData={dealTypesData}
            isDealTypesLoading={isDealTypesLoading}
            getCompaniesArray={getCompaniesArray}
            isCompaniesLoading={
              isCompaniesAsAdminLoading || isCompaniesAsConsultantLoading
            }
            isOpen={isOpen}
          />
        </>
      )}
      <TableContainer sx={{ minHeight: '350px' }} component={Paper}>
        <RequestHandler
          loading={isDealsLoading}
          isNoData={!dealsArray?.length}
          height='350px'
        >
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => {
                  if (headCell?.label) {
                    return (
                      <TableSortCell
                        align={headCell?.align}
                        order={filters.order}
                        field={filters.field}
                        setFilters={setFilters}
                        key={headCell.label}
                        label={headCell.label}
                        sortId={headCell?.sortId}
                      />
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dealsArray?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='left'>{row?.name}</TableCell>
                  <TableCell align='left'>
                    {getFormattedDate(row?.createdAt)}
                  </TableCell>
                  <TableCell align='left'>
                    {getFormattedDate(row?.approvedAt)}
                  </TableCell>
                  <TableCell align='left'>{row?.gross?.toFixed(2)}</TableCell>
                  <TableCell align='left'>
                    <Chip
                      label={getStatusDeals(row?.status).label}
                      color={getStatusDeals(row?.status).color}
                    />
                  </TableCell>

                  <TableCell align='center'>
                    <IconButton
                      onClick={() => {
                        handleClickEditButton(row.id, row?.dealChat?.id);
                      }}
                    >
                      {!isUserClient ? <Edit /> : <KeyboardArrowRight />}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </RequestHandler>
      </TableContainer>
      {dealsArray?.length ? (
        <Box mt={4}>
          <Pagination
            page={pagePagination}
            onChange={(_, page) => {
              setPagePagination(page);
            }}
            count={pageQuantity}
            variant='outlined'
            color='primary'
          />
        </Box>
      ) : null}
    </MainLayout>
  );
};
