export const getStatusDeals = (status) => {
  switch (status) {
    case 'pending':
      return { color: 'info', label: 'In attesa di' };
    case 'pendingForPermission':
      return { color: 'warning', label: 'In attesa di autorizzazione' };
    case 'bill':
      return { color: 'error', label: 'Conto' };
    case 'cachOut':
      return { color: 'success', label: 'Incassare' };
  }
};
