import React, { useState } from 'react';

import {
  useIssueDealReportAsAdminMutation,
  useIssueDealReportAsConsultantMutation,
  useRessueDealReportAsAdminMutation,
  useRessueDealReportAsConsultantMutation,
} from './useMutations';

export const useIssueDealReport = (
  dealReportId,
  isAdmin,
  isConsultant,
  input = {},
) => {
  const [isFile, setIsFile] = useState(false);

  const [
    issueDealReportAsConsultantMutation,
    { loading: issueDealReportAsConsultantLoading },
  ] = useIssueDealReportAsConsultantMutation(input);

  const [
    reissueDealReportAsConsultantMutation,
    { loading: reissueDealReportAsConsultantLoading },
  ] = useRessueDealReportAsConsultantMutation(input);

  const [
    issueDealReportAsAdminMutation,
    { loading: issueDealReportAsAdminLoading },
  ] = useIssueDealReportAsAdminMutation(input);

  const [
    reissueDealReportAsAdminMutation,
    { loading: reissueDealReportAsAdminLoading },
  ] = useRessueDealReportAsAdminMutation(input);

  const handleIssueDealReport = (file) => {
    if (isConsultant) {
      !isFile
        ? issueDealReportAsConsultantMutation({
            variables: { input: { dealReportId, file } },
          })
        : reissueDealReportAsConsultantMutation({
            variables: { input: { dealReportId, file } },
          });
    }
    if (isAdmin) {
      !isFile
        ? issueDealReportAsAdminMutation({
            variables: { input: { dealReportId, file } },
          })
        : reissueDealReportAsAdminMutation({
            variables: { input: { dealReportId, file } },
          });
    }

    setIsFile(false);
  };
  return [
    setIsFile,
    handleIssueDealReport,
    issueDealReportAsConsultantLoading,
    reissueDealReportAsConsultantLoading,
    issueDealReportAsAdminLoading,
    reissueDealReportAsAdminLoading,
  ];
};
