import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import create from 'zustand';

import { API_HOST, SOCKET_HOST } from '../constants/constants';
import { WebSocketLink } from './ws-link';

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  uri: `${API_HOST}`,
});

const wsLink = new WebSocketLink({
  url: `${SOCKET_HOST}`,
  connectionParams: () => {
    const token = localStorage.getItem('token');

    return {
      Authorization: `Bearer ${token}`,
    };
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      date: Date.now(),
    });
  }
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token');
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
