import PropTypes from 'prop-types';

export const NoDataText = ({ message = 'Nessun dato' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>{message}</p>
    </div>
  );
};

NoDataText.propTypes = {
  message: PropTypes.string,
};
