import { Grid } from '@mui/material';
import React from 'react';

import { MainLayout, RequestHandler } from '../../components';
import { ChatContextProvider, useAuthContext } from '../../contexts';
import { CommunicationChat } from './CommunicationChat';
import { CommunicationChats } from './CommunicationChats';

export const Communication = () => {
  const { user } = useAuthContext();

  return (
    <ChatContextProvider>
      <RequestHandler>
        <MainLayout>
          <Grid container spacing={3} xs={12} item>
            <Grid item xs={3}>
              <CommunicationChats user={user} />
            </Grid>
            <Grid item xs={9}>
              <CommunicationChat user={user} />
            </Grid>
          </Grid>
        </MainLayout>
      </RequestHandler>
    </ChatContextProvider>
  );
};
