import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { dealSchema } from '../../validation';

export const CreateProjectModal = ({
  isOpen,
  onClose,
  onSubmit,
  dealTypesData,
  isDealTypesLoading,
  getCompaniesArray,
  isCompaniesLoading,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(dealSchema),
    defaultValues: {
      dealType: null,
      dealCompany: null,
      gross: null,
      net: null,
    },
  });

  const dealTypesArray =
    dealTypesData?.getDealTypes?.dealTypes?.map((deal) => {
      return { id: deal?.id, label: deal?.name };
    }) || [];

  const companyOptionsArray =
    getCompaniesArray()?.map((company) => {
      return { id: company?.id, label: company?.name };
    }) || [];

  const handleOnSubmit = async (values) => {
    await onSubmit(values);
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Creare pratica</DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-gross'>Schifoso</InputLabel>
              <OutlinedInput
                id='deal-gross'
                placeholder='Schifoso'
                fullWidth
                type='number'
                error={Boolean(errors.gross)}
                {...register('gross')}
              />
            </Stack>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-gross'>Rete</InputLabel>
              <OutlinedInput
                id='deal-net'
                placeholder='Rete'
                fullWidth
                type='number'
                error={Boolean(errors.net)}
                {...register('net')}
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-type-selector'>
                Tipo di affare
              </InputLabel>
              <Controller
                name='dealType'
                control={control}
                render={({ field: { onChange, ...restField } }) => (
                  <Autocomplete
                    {...restField}
                    onChange={(event, value) => {
                      onChange({ target: { value } });
                    }}
                    loading={isDealTypesLoading}
                    isOptionEqualToValue={(option, value) =>
                      +option.id === +value
                    }
                    id='deal-type-selector'
                    options={dealTypesArray}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(errors.dealType)}
                        {...params}
                        placeholder='Tipo di affare'
                      />
                    )}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-company-selector'>Azienda</InputLabel>
              <Controller
                name='dealCompany'
                control={control}
                render={({ field: { onChange, ...restField } }) => (
                  <Autocomplete
                    {...restField}
                    onChange={(event, value) => {
                      onChange({ target: { value } });
                    }}
                    loading={isCompaniesLoading}
                    isOptionEqualToValue={(option, value) =>
                      +option.id === +value
                    }
                    id='deal-company-selector'
                    options={companyOptionsArray}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        error={Boolean(errors.dealCompany)}
                        {...params}
                        placeholder='Azienda'
                      />
                    )}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} type='submit'>
          Creare
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateProjectModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  dealTypesData: PropTypes.object,
  isDealTypesLoading: PropTypes.bool,
  getCompaniesArray: PropTypes.func,
  isCompaniesLoading: PropTypes.bool,
};
