import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { ApolloProvider } from '@apollo/client';
import DateFnsAdapter from '@date-io/date-fns';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ModalLayout } from './components';
import {
  AuthContextProvider,
  ModalContextProvider,
  SearchProvider,
} from './contexts';
import { client } from './graphql/client';
import { RouterWrapper } from './router/Router';
import { theme } from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <ModalContextProvider>
            <ModalLayout>
              <AuthContextProvider>
                <BrowserRouter>
                  <SearchProvider>
                    <RouterWrapper />
                    <ToastContainer position='bottom-right' />
                  </SearchProvider>
                </BrowserRouter>
              </AuthContextProvider>
            </ModalLayout>
          </ModalContextProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
