import * as yup from 'yup';

import { messages as M } from '../messages';

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string(M.PROMPT_PASSWORD)
    .min(4, M.MIN_SYMBOL)
    .required(M.REQUIRE_MESSAGE),
});
