import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';

export const AuthContext = React.createContext();

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

const actionTypes = {
  SUCCESS_AUTH: 'SUCCESS_AUTH',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
};

function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      localStorage.removeItem('token');
      return {
        user: null,
        hasToken: false,
      };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        user: action.payload,
        hasToken: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AuthContextProvider({ children }) {
  const client = useApolloClient();

  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    hasToken: !!localStorage.getItem('token'),
  });

  const signIn = (payload) => {
    dispatch({ type: actionTypes.SUCCESS_AUTH, payload });
  };

  const signOut = () => {
    client.clearStore();
    dispatch({ type: actionTypes.LOGOUT });
  };

  const userRole = state?.user?.role;

  const isUserClient = userRole === 'client';

  const isUserAdmin = userRole === 'admin';

  const isUserConsultant = userRole === 'consultant';

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!state.user,
        hasToken: state.hasToken,
        user: state.user,
        isUserClient,
        isUserAdmin,
        isUserConsultant,
        signOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
