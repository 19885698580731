import { Edit } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { EditProjectModal } from '../../components';
import { getFormattedDate, getStatusDeals } from '../../utils';

export const ProjectInfo = ({
  name,
  status,
  createdDate,
  approvedAt,
  gross,
  net,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card sx={{ height: '100%', overflowY: 'auto' }}>
      <EditProjectModal
        status={status}
        gross={gross}
        net={net}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <CardContent>
        <Stack justifyContent='space-between' direction='row' mb={3}>
          <Typography fontSize={16}>{name}</Typography>
          <IconButton
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        </Stack>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow sx={{ 'td, th': { border: 0, paddingY: 0 } }}>
                <TableCell>Data Inizio</TableCell>
                <TableCell>Stato</TableCell>
                <TableCell>Scadenza</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ 'td, th': { border: 0 } }}>
                <TableCell>{getFormattedDate(createdDate)}</TableCell>
                <TableCell>
                  <Chip
                    label={getStatusDeals(status)?.label}
                    color={getStatusDeals(status)?.color}
                  />
                </TableCell>
                <TableCell>{getFormattedDate(approvedAt)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

ProjectInfo.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  createdDate: PropTypes.string,
  approvedAt: PropTypes.string,
  gross: PropTypes.number,
  net: PropTypes.number,
};
