import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';

import { RequestHandler } from '../../../components';
import { useEditCompanyMutation } from '../../../hooks';

export const CompanyInfoForm = ({ userData, companyById }) => {
  const [editCompany, { loading: editCompanyLoading }] =
    useEditCompanyMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      companyName: companyById?.getCompanyById?.name || '',
      companyNumber: companyById?.getCompanyById?.cf || '',
    },
  });

  const onEdit = (input) => {
    if (userData?.getUserById?.role !== 'admin') {
      editCompany({
        variables: {
          companyId: userData?.getUserById?.companyId,
          input: {
            name: input.companyName,
            cf: input.companyNumber,
          },
        },
      });
    }
  };
  return (
    <RequestHandler loading={editCompanyLoading}>
      <Box
        mt={'30px'}
        component={'form'}
        onSubmit={handleSubmit(onEdit)}
        noValidate
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel htmlFor='company-name'>Azienda</InputLabel>
                <OutlinedInput
                  id='company-name'
                  type='text'
                  placeholder=''
                  fullWidth
                  autoComplete='companyName'
                  error={Boolean(errors.companyName)}
                  {...register('companyName')}
                />
                {errors.companyName && (
                  <FormHelperText
                    error
                    id='standard-weight-helper-text-companyName'
                  >
                    {errors.companyName.message.toString()}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <InputLabel htmlFor='company-number'>
                  Codice Fiscale Azienda
                </InputLabel>
                <OutlinedInput
                  id='company-number'
                  type='text'
                  placeholder=''
                  fullWidth
                  autoComplete='companyNumber'
                  error={Boolean(errors.companyNumber)}
                  {...register('companyNumber')}
                />
                {errors.companyNumber && (
                  <FormHelperText
                    error
                    id='standard-weight-helper-text-companyNumber'
                  >
                    {errors.companyNumber.message.toString()}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Button
          disabled={isSubmitting}
          type='submit'
          variant='contained'
          sx={{ marginTop: '24px' }}
          startIcon={<AddIcon />}
        >
          {'Modificata azienda'}
        </Button>
      </Box>
    </RequestHandler>
  );
};

CompanyInfoForm.propTypes = {
  userData: PropTypes.object,
  companyById: PropTypes.object,
};
