import PropTypes from 'prop-types';
import React from 'react';

import { useAuthContext } from '../../contexts';

export const RolesWrapper = ({ rolesArray, children }) => {
  const { user } = useAuthContext();
  const isShowPage = rolesArray.includes(user?.role);

  if (isShowPage) {
    return <>{children}</>;
  } else {
    return null;
  }
};

RolesWrapper.propTypes = {
  rolesArray: PropTypes.array,
  children: PropTypes.node,
};
