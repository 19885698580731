import { Box, Container, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { LogoSection } from '../LogoSection/LogoSection';

export const AuthLayout = ({ children }) => (
  <Box sx={{ minHeight: '100vh' }}>
    <Grid
      container
      direction='column'
      sx={{
        minHeight: '100vh',
      }}
    >
      <Grid item sx={{ ml: 3, mt: 3 }}>
        <LogoSection />
      </Grid>
      <Grid item>
        <Grid item container justifyContent='center' alignItems='center' mt={8}>
          <Container component='main' maxWidth='xs'>
            {children}
          </Container>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

AuthLayout.propTypes = {
  children: PropTypes.node,
};
