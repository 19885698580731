import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DOCUMENT_STATUS } from '../../constants/constants';
import { useUpdateStatusInDealDocumentMutation } from '../../hooks';

export const UpdateStatusInDealDocumentModal = ({
  open,
  onClose,
  dealDocumentId,
  setDealDocumentId,
}) => {
  const [newStatus, setNewStatus] = useState('');

  const [
    updateStatusInDealDocumentMutation,
    { loading: updateStatusInDealDocumentLoading },
  ] = useUpdateStatusInDealDocumentMutation();

  const handleUpdateStatusDealDocument = () => {
    updateStatusInDealDocumentMutation({
      variables: { dealDocumentId, newStatus },
    });
    setNewStatus('');
  };
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Selezionare lo stato</DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Box
          noValidate
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <FormControl sx={{ width: '200px' }}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Stato</Typography>
            <Select
              id='select-new-status'
              name='newStatus'
              onChange={(e) => {
                setNewStatus(e.target.value);
              }}
              value={newStatus}
            >
              {Object.entries(DOCUMENT_STATUS).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleUpdateStatusDealDocument();
            setDealDocumentId(0);
            onClose();
          }}
        >
          Modificato
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateStatusInDealDocumentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dealDocumentId: PropTypes.bool,
  setDealDocumentId: PropTypes.func,
};
