import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  EditDealInteractionModal,
  NoDataText,
  RequestHandler,
} from '../../components';
import {
  DEAL_INTERACTION_TYPES,
  LIMIT_DEAL_INTERACTIONS_PAGE,
} from '../../constants/constants';
import {
  useDeleteDealInteractionMutation,
  useEditDealInteractionMutation,
} from '../../hooks';
import { getExistData, getFormattedDate } from '../../utils';

export const ProjectInteractions = ({
  dealInteractionLogs,
  setModalOpen,
  interactionsPage,
  setInteractionsPage,
  interactionsLoading,
}) => {
  const [deleteInteraction, { loading: deleteInteractionLoading }] =
    useDeleteDealInteractionMutation();

  const [editInteraction] = useEditDealInteractionMutation();

  const [deletingLogId, setDeletingLogId] = useState(null);

  const [editingLog, setEditingLog] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleModalOpen = (log) => {
    setEditingLog(log);
    setIsEditModalOpen(true);
  };

  const handleEditInteraction = (logId, values) => {
    editInteraction({
      variables: {
        id: logId,
        editableFields: values,
      },
    });
  };

  const handleDeleteInteraction = (logId) => {
    setDeletingLogId(logId);
    deleteInteraction({ variables: { id: logId } });
  };
  const InteractionLogsArray = dealInteractionLogs?.dealInteractionLogs;

  const pageQuantity =
    Math.ceil(dealInteractionLogs?.count / LIMIT_DEAL_INTERACTIONS_PAGE) || 1;

  return (
    <Card>
      {isEditModalOpen && (
        <EditDealInteractionModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditInteraction}
          log={editingLog}
        />
      )}
      <CardContent>
        <Stack
          justifyContent='space-between'
          direction='row'
          alignItems='center'
          mb={3}
        >
          <Typography fontSize={16}>Interazioni</Typography>
          <IconButton onClick={setModalOpen}>
            <Add />
          </IconButton>
        </Stack>
        <TableContainer>
          <RequestHandler
            loading={interactionsLoading && !InteractionLogsArray}
          >
            {InteractionLogsArray?.length ? (
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Dettagli</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {InteractionLogsArray?.map((log) => (
                    <TableRow
                      key={log.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{getFormattedDate(log?.createdAt)}</TableCell>
                      <TableCell>
                        {getExistData(DEAL_INTERACTION_TYPES[log?.type])}
                      </TableCell>
                      <TableCell>
                        <Stack justifyContent='space-between' direction='row'>
                          <Typography marginY='auto'>
                            {getExistData(log?.text)}
                          </Typography>
                          <Stack spacing={1} direction='row'>
                            <IconButton onClick={() => handleModalOpen(log)}>
                              <Edit />
                            </IconButton>
                            {deleteInteractionLoading &&
                            deletingLogId === log.id ? (
                              <CircularProgress />
                            ) : (
                              <IconButton
                                onClick={() => handleDeleteInteraction(log.id)}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoDataText />
            )}
          </RequestHandler>
        </TableContainer>
        {dealInteractionLogs?.count > LIMIT_DEAL_INTERACTIONS_PAGE ? (
          <Box mt={4}>
            <Pagination
              page={interactionsPage}
              onChange={(_, page) => {
                setInteractionsPage(page);
              }}
              count={pageQuantity}
              variant='outlined'
              color='primary'
            />
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

ProjectInteractions.propTypes = {
  dealInteractionLogs: PropTypes.object,
  setModalOpen: PropTypes.func,
  interactionsPage: PropTypes.number,
  setInteractionsPage: PropTypes.func,
  interactionsLoading: PropTypes.bool,
};
