import * as yup from 'yup';

import { messages as M } from '../messages';

export const companyValidationSchema = yup.object().shape({
  name: yup.string().required(M.REQUIRE_MESSAGE),
  cf: yup.string().required(M.REQUIRE_MESSAGE),
  location: yup.string().required(M.REQUIRE_MESSAGE),
  size: yup.string().required(M.REQUIRE_MESSAGE),
  leadConsultantId: yup.number().nullable().required(M.REQUIRE_MESSAGE),
  dmUsedPercentage: yup
    .number()
    .max(100)
    .nullable()
    .transform((value) => (value ? value : null)),
  dmUsed: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  dmYearN: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  dmYearN1: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  dmYearN2: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  yearN: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  yearN1: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  yearN2: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
});
