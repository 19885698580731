import { ROUTES } from '../constants/constants';
import {
  Communication,
  Companies,
  CompanyWrapper,
  Dashboard,
  DevPage,
  Documents,
  DocumentWrapper,
  ForgotPassword,
  Login,
  NewPassword,
  Project,
  Projects,
  Report,
  Users,
  UserWrapper,
} from '../pages';

export const routes = [
  {
    path: ROUTES.home,
    element: Dashboard,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.login,
    element: Login,
  },
  {
    path: ROUTES.forgotPassword,
    element: ForgotPassword,
  },
  {
    path: ROUTES.newPassword,
    element: NewPassword,
  },
  {
    path: ROUTES.devPage,
    element: DevPage,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.projects,
    element: Projects,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.project,
    element: Project,
    roles: ['admin', 'consultant'],
  },
  {
    path: ROUTES.editProject,
    element: Project,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.communication,
    element: Communication,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.documents,
    element: Documents,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.document,
    element: DocumentWrapper,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.editDocument,
    element: DocumentWrapper,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.report,
    element: Report,
    roles: ['admin', 'consultant', 'client'],
  },
  {
    path: ROUTES.users,
    element: Users,
    roles: ['admin', 'consultant'],
  },
  {
    path: ROUTES.editUser,
    element: UserWrapper,
    roles: ['admin', 'client'],
  },
  {
    path: ROUTES.user,
    element: UserWrapper,
    roles: ['admin'],
  },
  {
    path: ROUTES.companies,
    element: Companies,
    roles: ['admin', 'consultant'],
  },
  {
    path: ROUTES.company,
    element: CompanyWrapper,
    roles: ['admin'],
  },
  {
    path: ROUTES.editCompany,
    element: CompanyWrapper,
    roles: ['admin'],
  },
];
