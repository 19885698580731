import * as yup from 'yup';

import { messages as M } from '../messages';

export const dealSchema = yup.object().shape({
  dealType: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string(),
    })
    .nullable()
    .required(M.REQUIRE_MESSAGE),
  dealCompany: yup
    .object()
    .shape({
      id: yup.number(),
      label: yup.string(),
    })
    .nullable()
    .required(M.REQUIRE_MESSAGE),
  gross: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null))
    .required(M.REQUIRE_MESSAGE),
  net: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null))
    .required(M.REQUIRE_MESSAGE),
});
