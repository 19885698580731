import { Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { AuthLayout } from '../../components';
import { ROUTES } from '../../constants/constants';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPassword = () => {
  return (
    <AuthLayout>
      <Grid container spacing={3} maxWidth='xs'>
        <Grid item xs={12}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography component='h1' variant='h5'>
              Dimenticato la password
            </Typography>
            <Typography
              component={Link}
              to={ROUTES.login}
              variant='body2'
              sx={{ textDecoration: 'none' }}
              color='primary'
            >
              Torna al login
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ForgotPasswordForm />
        </Grid>
      </Grid>
    </AuthLayout>
  );
};
