import { Edit } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Card,
  CardContent,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AddDocumentModal } from '../../components';
import { UpdateStatusInDealDocumentModal } from '../../components/UpdateStatusInDealDocumentModal/UpdateStatusInDealDocumentModal';
import { API_DOMAIN, DOCUMENT_STATUS } from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import {
  useUploadDocumentFileMutation,
  useUploadDocumentLinkMutation,
} from '../../hooks';
import { useUploadDocumentFile } from '../../hooks/useUploadDocumentFile';
import { getColorStatusDoc, getExistData } from '../../utils';

export const ProjectDocuments = ({ dealDocuments }) => {
  const [dealDocumentId, setDealDocumentId] = useState(0);
  const [isUpdateStatusDocumentModalOpen, setIsUpdateStatusDocumentModalOpen] =
    useState(false);

  const [
    onSubmit,
    uploadFileLoading,
    uploadLinkLoading,
    isModalOpen,
    setIsModalOpen,
  ] = useUploadDocumentFile(dealDocumentId);

  return (
    <Card>
      <CardContent>
        <AddDocumentModal
          onSubmit={onSubmit}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        <UpdateStatusInDealDocumentModal
          open={isUpdateStatusDocumentModalOpen}
          onClose={() => setIsUpdateStatusDocumentModalOpen(false)}
          dealDocumentId={dealDocumentId}
          setDealDocumentId={setDealDocumentId}
        />
        <Stack
          justifyContent='space-between'
          direction='row'
          alignItems='center'
          mb={3}
        >
          <Typography fontSize={16}>Documentazione</Typography>
        </Stack>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableBody>
              {dealDocuments?.map((doc) => (
                <TableRow
                  key={doc.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{getExistData(doc)}</TableCell>
                  <TableCell align='center'>
                    <Chip
                      onClick={() => {
                        setIsUpdateStatusDocumentModalOpen(true);
                        setDealDocumentId(doc.id);
                      }}
                      label={DOCUMENT_STATUS[doc?.status]}
                      color={getColorStatusDoc(doc?.status)}
                    />
                  </TableCell>
                  <TableCell>
                    {doc.fileName || doc.externalLink ? (
                      doc.fileName ? (
                        <a
                          href={`${API_DOMAIN}/uploads/deal-documents/${doc.fileName}`}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none' }}
                        >
                          <DownloadIcon />
                        </a>
                      ) : (
                        <a
                          href={doc.externalLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none' }}
                        >
                          <DownloadIcon />
                        </a>
                      )
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

ProjectDocuments.propTypes = {
  dealDocuments: PropTypes.array,
};
