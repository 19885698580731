import * as yup from 'yup';

import { messages as M } from '../messages';

export const userValidationSchema = yup.object().shape({
  firstName: yup.string().required(M.REQUIRE_MESSAGE),
  email: yup
    .string(M.PROMPT_MAIL)
    .email(M.PROMPT_MAIL)
    .required(M.REQUIRE_MESSAGE),
  lastName: yup.string().required(M.REQUIRE_MESSAGE),
  cellPhone: yup
    .string()
    .required(M.REQUIRE_MESSAGE)
    .matches(/\+\d{12}/, M.INCORRECT_PHONE_MESSAGE)
    .max(13, M.INCORRECT_PHONE_MESSAGE)
    .min(13, M.INCORRECT_PHONE_MESSAGE),
  landlinePhone: yup
    .string()
    .matches(/\+\d{12}/, M.INCORRECT_PHONE_MESSAGE)
    .max(13, M.INCORRECT_PHONE_MESSAGE)
    .min(13, M.INCORRECT_PHONE_MESSAGE)
    .nullable()
    .transform((value) => (value ? value : null)),
  role: yup.string().required(M.REQUIRE_MESSAGE),

  companyId: yup.number().when('role', {
    is: (val) => {
      return val === 'client';
    },
    then: yup.number().required(M.REQUIRE_MESSAGE),
    otherwise: yup
      .number()
      .nullable()
      .transform((value) => (value ? value : null)),
  }),
});
