import PropTypes from 'prop-types';

import { BasicModal } from './ModalController';

export const ModalLayout = ({ children }) => {
  return (
    <>
      {children}
      <BasicModal />
    </>
  );
};

ModalLayout.propTypes = {
  children: PropTypes.node,
};
