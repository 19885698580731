import { gql } from '@apollo/client';

import {
  COMPANY_FRAGMENT,
  DEAL_CONSULTANT_FRAGMENT,
  DEAL_DOCUMENT_FRAGMENT,
  DEAL_FRAGMENT,
  DEAL_INTERACTION_FRAGMENT,
  DEAL_MESSAGE_FRAGMENT,
  DEAL_REPORT_FRAGMENT,
  USER_FRAGMENT,
} from './fragments';

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestUserPasswordReset($email: String!) {
    requestUserPasswordReset(email: $email)
  }
`;

export const PASSWORD_RESET = gql`
  ${USER_FRAGMENT}
  mutation resetUserPassword(
    $passwordResetToken: String!
    $newPassword: String!
  ) {
    resetUserPassword(
      passwordResetToken: $passwordResetToken
      newPassword: $newPassword
    ) {
      accessToken
      user {
        ...userFragment
      }
    }
  }
`;

export const CREATE_USER = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...userFragment
      role
      cellPhone
      landlinePhone
      createdAt
      companyInWhichAsClient {
        ...companyFragment
      }
    }
  }
`;

export const EDIT_USER = gql`
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
  mutation editUser($id: Int!, $input: EditUserInput!) {
    editUser(id: $id, input: $input) {
      ...userFragment
      role
      cellPhone
      landlinePhone
      createdAt
      companyInWhichAsClient {
        ...companyFragment
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  ${USER_FRAGMENT}
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...companyFragment
      client {
        ...userFragment
      }
      leadConsultant {
        ...userFragment
      }
      deals {
        id
      }
    }
  }
`;
export const EDIT_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  mutation editCompany($input: EditCompanyInput!, $companyId: Int!) {
    editCompany(input: $input, companyId: $companyId) {
      ...companyFragment
    }
  }
`;

export const CREATE_DEAL = gql`
  ${DEAL_FRAGMENT}
  mutation createDeal($input: CreateDealInput!) {
    createDeal(input: $input) {
      ...dealFragment
    }
  }
`;

export const EDIT_DEAL = gql`
  ${DEAL_FRAGMENT}
  mutation editDeal($id: Int!, $input: EditDealInput!) {
    editDealById(id: $id, input: $input) {
      ...dealFragment
    }
  }
`;

export const ADD_DEAL_CONSULTANT_ASSISTANT = gql`
  ${DEAL_CONSULTANT_FRAGMENT}
  mutation addDealConsultantAssistantAsConsultant(
    $input: AddDealConsultantAssistantAsConsultantInput!
  ) {
    addDealConsultantAssistantAsConsultant(input: $input) {
      ...dealConsultantFragment
    }
  }
`;

export const REMOVE_DEAL_CONSULTANT_ASSISTANT = gql`
  mutation removeDealConsultantAssistantAsConsultant(
    $input: RemoveDealConsultantAssistantAsConsultantInput!
  ) {
    removeDealConsultantAssistantAsConsultant(input: $input)
  }
`;

export const ADD_DEAL_INTERACTION = gql`
  ${DEAL_INTERACTION_FRAGMENT}
  mutation createDealInteractionLog($input: CreateDealInteractionLog!) {
    createDealInteractionLog(input: $input) {
      ...dealInteractionFragment
    }
  }
`;

export const EDIT_DEAL_INTERACTION = gql`
  ${DEAL_INTERACTION_FRAGMENT}
  mutation editDealInteractionLogById(
    $id: Int!
    $editableFields: EditDealInteractionLog!
  ) {
    editDealInteractionLogById(id: $id, editableFields: $editableFields) {
      ...dealInteractionFragment
    }
  }
`;

export const DELETE_DEAL_INTERACTION = gql`
  mutation deleteDealInteractionLogById($id: Int!) {
    deleteDealInteractionLogById(id: $id)
  }
`;

export const UPLOAD_DOCUMENT_FILE = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  mutation uploadDocumentFile(
    $dealDocumentId: Int!
    $input: UploadFileInDealDocumentInput!
  ) {
    uploadFileInDealDocument(dealDocumentId: $dealDocumentId, input: $input) {
      ...dealDocumentFragment
    }
  }
`;

export const UPLOAD_DOCUMENT_LINK = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  mutation uploadDocumentLink(
    $dealDocumentId: Int!
    $input: UploadExternalLinkInDealDocumentInput!
  ) {
    uploadExternalLinkInDealDocument(
      dealDocumentId: $dealDocumentId
      input: $input
    ) {
      ...dealDocumentFragment
    }
  }
`;

export const EDIT_DOCUMENT_DATES = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  mutation editDocumentDates(
    $dealDocumentId: Int!
    $input: UploadExternalLinkInDealDocumentInput!
  ) {
    uploadExternalLinkInDealDocument(
      dealDocumentId: $dealDocumentId
      input: $input
    ) {
      ...dealDocumentFragment
    }
  }
`;

export const REQUEST_DEAL_REPORT_AS_CLIENT = gql`
  ${DEAL_REPORT_FRAGMENT}
  mutation requestDealReportAsClient($input: RequestDealReportInput!) {
    requestDealReportAsClient(input: $input) {
      ...dealReportFragment
    }
  }
`;

export const UPDATE_DEAL_REPORTS_STATUS_AS_CONSULTANT = gql`
  mutation updateDealReportStatusAsConsultant(
    $input: UpdateDealReportStatusInput!
  ) {
    updateDealReportStatusAsConsultant(input: $input) {
      id
      status
    }
  }
`;

export const ISSUE_DEAL_REPORT_AS_CONSULTANT = gql`
  ${DEAL_REPORT_FRAGMENT}
  mutation issueDealReportAsConsultant($input: IssueDealReportInput!) {
    issueDealReportAsConsultant(input: $input) {
      ...dealReportFragment
    }
  }
`;
export const REISSUE_DEAL_REPORT_AS_CONSULTANT = gql`
  ${DEAL_REPORT_FRAGMENT}
  mutation reissueDealReportAsConsultant($input: ReissueDealReportInput!) {
    reissueDealReportAsConsultant(input: $input) {
      ...dealReportFragment
    }
  }
`;

export const UPDATE_DEAL_REPORTS_STATUS_AS_ADMIN = gql`
  mutation updateDealReportStatusAsAdmin($input: UpdateDealReportStatusInput!) {
    updateDealReportStatusAsAdmin(input: $input) {
      id
      status
    }
  }
`;

export const UPDATE_STATUS_IN_DEAL_DOCUMENT = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  mutation updateStatusInDealDocument(
    $dealDocumentId: Int!
    $newStatus: DealDocumentStatusEnum!
  ) {
    updateStatusInDealDocument(
      dealDocumentId: $dealDocumentId
      newStatus: $newStatus
    ) {
      ...dealDocumentFragment
    }
  }
`;

export const ISSUE_DEAL_REPORT_AS_ADMIN = gql`
  ${DEAL_REPORT_FRAGMENT}
  mutation issueDealReportAsAdmin($input: IssueDealReportInput!) {
    issueDealReportAsAdmin(input: $input) {
      ...dealReportFragment
    }
  }
`;
export const REISSUE_DEAL_REPORT_AS_ADMIN = gql`
  ${DEAL_REPORT_FRAGMENT}
  mutation reissueDealReportAsAdmin($input: ReissueDealReportInput!) {
    reissueDealReportAsAdmin(input: $input) {
      ...dealReportFragment
    }
  }
`;

export const SEND_DEAL_MESSAGE = gql`
  ${DEAL_MESSAGE_FRAGMENT}
  ${USER_FRAGMENT}
  mutation sendDealMessage($input: CreateDealMessageInput!) {
    sendDealMessage(input: $input) {
      ...dealMessageFragment
      user {
        ...userFragment
      }
    }
  }
`;

export const READ_DEAL_MESSAGE_BY_ID = gql`
  mutation readDealMessageById($dealMessageId: Float!) {
    readDealMessageById(dealMessageId: $dealMessageId)
  }
`;

export const READ_LATEST_DEAL_MESSAGES_BY_DEAL_CHAT_ID = gql`
  mutation readLatestDealMessagesByDealChatId($dealChatId: Float!) {
    readLatestDealMessagesByDealChatId(dealChatId: $dealChatId)
  }
`;

export const EDIT_DEAL_DOCUMENT_DATES = gql`
  ${DEAL_DOCUMENT_FRAGMENT}
  mutation editDealDocumentDates(
    $dealDocumentId: Int!
    $input: EditDealDocumentDatesInput!
  ) {
    editDealDocumentDates(dealDocumentId: $dealDocumentId, input: $input) {
      ...dealDocumentFragment
    }
  }
`;

export const CREATE_DEAL_DOCUMENT = gql`
  mutation CreateDealDocument($input: CreateDealDocumentInput!) {
    createDealDocument(input: $input) {
      createdAt
      dealId
      id
      name
    }
  }
`;
export const EDIT_DEAL_DOCUMENT = gql`
  mutation EditDealDocumentById($id: Int!, $input: EditDealDocumentInput!) {
    editDealDocumentById(id: $id, input: $input) {
      dealId
      id
      fileName
      name
    }
  }
`;
