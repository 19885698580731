import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MainLayout } from '../../components';
import { RequestHandler } from '../../components/RequestHandler/RequestHandler';
import { COMPANY_SIZE } from '../../constants/constants';
import {
  useCreateCompanyMutation,
  useEditCompanyMutation,
  useGetCompanyByIdQuery,
  useGetConsultantsQuery,
} from '../../hooks';
import { palette } from '../../theme/foundations';
import { companyValidationSchema } from '../../validation';

export const CompanyWrapper = () => {
  const { data: companyData, loading: companyLoading } =
    useGetCompanyByIdQuery();
  const { data: consultantsData, loading: consultantsLoading } =
    useGetConsultantsQuery();
  return (
    <RequestHandler loading={companyLoading || consultantsLoading}>
      <Company companyData={companyData} consultantsData={consultantsData} />
    </RequestHandler>
  );
};

const Company = ({ companyData, consultantsData }) => {
  const { id } = useParams();

  const [createCompanyMutation, { loading: createCompanyLoading }] =
    useCreateCompanyMutation();

  const [editCompanyMutation, { loading: editCompanyLoading }] =
    useEditCompanyMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: yupResolver(companyValidationSchema),
    defaultValues: {
      name: companyData?.getCompanyById?.name || '',
      cf: companyData?.getCompanyById?.cf || '',
      location: companyData?.getCompanyById?.location || '',
      size: companyData?.getCompanyById?.size || '',
      leadConsultantId: companyData?.getCompanyById?.leadConsultantId || null,
      dmUsedPercentage: companyData?.getCompanyById?.dmUsedPercentage || null,
      dmUsed: companyData?.getCompanyById?.dmUsed || null,
      dmYearN: companyData?.getCompanyById?.dmYearN || null,
      dmYearN1: companyData?.getCompanyById?.dmYearN1 || null,
      dmYearN2: companyData?.getCompanyById?.dmYearN2 || null,
      yearN: companyData?.getCompanyById?.yearN || null,
      yearN1: companyData?.getCompanyById?.yearN1 || null,
      yearN2: companyData?.getCompanyById?.yearN2 || null,
    },
  });

  const onSubmit = (input) => {
    createCompanyMutation({ variables: { input } });
  };

  const onEdit = (input) => {
    editCompanyMutation({ variables: { companyId: Number(id), input: input } });
  };

  return (
    <RequestHandler loading={createCompanyLoading || editCompanyLoading}>
      <MainLayout>
        <Box
          component={'form'}
          onSubmit={handleSubmit(!id ? onSubmit : onEdit)}
          noValidate
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} item>
                  <Grid item xs={6}>
                    <InputLabel htmlFor='company-name'>
                      Nome dell &apos;azienda
                    </InputLabel>
                    <OutlinedInput
                      id='company-name'
                      type='text'
                      placeholder="Nome dell 'azienda"
                      fullWidth
                      autoComplete='company-name'
                      error={Boolean(errors.name)}
                      {...register('name')}
                    />
                    {errors.name && (
                      <FormHelperText
                        error
                        id='standard-weight-helper-text-company-name'
                      >
                        {errors.name.message.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel htmlFor='compamy-cf'>Azienda CF</InputLabel>
                    <OutlinedInput
                      id='compamy-cf'
                      type='text'
                      placeholder='Azienda CF'
                      fullWidth
                      autoComplete='compamy-cf'
                      error={Boolean(errors.cf)}
                      {...register('cf')}
                    />
                    {errors.cf && (
                      <FormHelperText
                        error
                        id='standard-weight-helper-text-compamy-cf'
                      >
                        {errors.cf.message.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={6}>
                    <InputLabel htmlFor='company-location'>
                      Sede dell&apos;azienda
                    </InputLabel>
                    <OutlinedInput
                      id='company-location'
                      type='text'
                      placeholder="Sede dell'azienda"
                      fullWidth
                      autoComplete='company-location'
                      error={Boolean(errors.location)}
                      {...register('location')}
                    />
                    {errors.location && (
                      <FormHelperText
                        error
                        id='standard-weight-helper-text-company-location'
                      >
                        {errors.location.message.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        Dimensioni aziendali
                      </Typography>
                      <Controller
                        name='size'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            id='select-company-size'
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            error={Boolean(errors.size)}
                          >
                            {Object.entries(COMPANY_SIZE).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        )}
                      />
                      {errors.size && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-lead-consultant-id'
                        >
                          {errors.size.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid container spacing={3} xs={12} item>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Typography sx={{ color: palette.text.typography }}>
                          ID del consulente principale
                        </Typography>
                        <Controller
                          name='leadConsultantId'
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <Select
                              id='select-consultant-id'
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              inputRef={ref}
                              error={Boolean(errors.leadConsultantId)}
                            >
                              {consultantsData.getUsers.users.map(
                                ({ id, firstName, lastName }) => (
                                  <MenuItem key={id} value={id}>
                                    {firstName} {lastName}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          )}
                        />
                        {errors.leadConsultantId && (
                          <FormHelperText
                            error
                            id='standard-weight-helper-text-lead-consultant-id'
                          >
                            {errors.leadConsultantId.message.toString()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='dm-used-percentage'>
                        dmUsedPercentage
                      </InputLabel>
                      <OutlinedInput
                        id='dm-used-percentage'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                          max: 100,
                        }}
                        placeholder='dmUsedPercentage'
                        fullWidth
                        autoComplete='dm-used-percentage'
                        {...register('dmUsedPercentage', {
                          valueAsNumber: true,
                        })}
                        error={Boolean(errors.dmUsedPercentage)}
                      />
                      {errors.dmUsedPercentage && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-dm-used-percentage'
                        >
                          {errors.dmUsedPercentage.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} xs={12} item>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='dm-used'>dmUsed</InputLabel>
                      <OutlinedInput
                        id='dm-used'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='dmUsed'
                        fullWidth
                        autoComplete='dm-used'
                        {...register('dmUsed', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.dmUsed && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-dm-used'
                        >
                          {errors.dmUsed.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='dm-year-N'>dmYearN</InputLabel>
                      <OutlinedInput
                        id='dm-year-N'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='dmYearN'
                        fullWidth
                        autoComplete='dm-yearN'
                        {...register('dmYearN', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.dmYearN && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-dm-yearN'
                        >
                          {errors.dmYearN.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} xs={12} item>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='dm-year-N1'>dmYearN1</InputLabel>
                      <OutlinedInput
                        id='dm-year-N1'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='dmYearN1'
                        fullWidth
                        autoComplete='dm-yearN1'
                        {...register('dmYearN1', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.dmYearN1 && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-dm-yearN1'
                        >
                          {errors.dmYearN1.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='dm-year-N2'>dmYearN2</InputLabel>
                      <OutlinedInput
                        id='dm-year-N2'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='dmYearN2'
                        fullWidth
                        autoComplete='dm-yearN2'
                        {...register('dmYearN2', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.dmYearN2 && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-dm-yearN2'
                        >
                          {errors.dmYearN2.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} xs={12} item>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='year-N'>yearN</InputLabel>
                      <OutlinedInput
                        id='year-N'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='dmYearN'
                        fullWidth
                        autoComplete='yearN'
                        {...register('yearN', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.yearN && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-yearN'
                        >
                          {errors.yearN.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='year-N1'>yearN1</InputLabel>
                      <OutlinedInput
                        id='year-N1'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='yearN1'
                        fullWidth
                        autoComplete='yearN1'
                        {...register('yearN1', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.yearN1 && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-yearN1'
                        >
                          {errors.yearN1.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} xs={12} item>
                    <Grid item xs={6}>
                      <InputLabel htmlFor='year-N2'>yearN2</InputLabel>
                      <OutlinedInput
                        id='year-N2'
                        inputProps={{
                          type: 'number',
                          step: 0.01,
                          min: 0,
                        }}
                        placeholder='yearN2'
                        fullWidth
                        autoComplete='yearN2'
                        {...register('yearN2', {
                          valueAsNumber: true,
                        })}
                      />
                      {errors.yearN2 && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-yearN2'
                        >
                          {errors.yearN2.message.toString()}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button
            disabled={isSubmitting}
            type='submit'
            sx={{ marginTop: '24px' }}
            variant='contained'
            startIcon={<AddIcon />}
          >
            {!companyData ? 'Aggiungi azienda' : 'Modificata azienda'}
          </Button>
        </Box>
      </MainLayout>
    </RequestHandler>
  );
};

Company.propTypes = {
  companyData: PropTypes.object,
  consultantsData: PropTypes.object,
};
