import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import { AddDealInteractionModal, RequestHandler } from '../../components';
import { useAuthContext } from '../../contexts';
import {
  useAddDealInteractionMutation,
  useGetDealAssistantsQuery,
  useGetDealDocumentsQuery,
  useGetDealInteractionsQuery,
  useGetDealQuery,
} from '../../hooks';
import { ProjectAssistants } from './ProjectAssistants';
import { ProjectChat } from './ProjectChat';
import { ProjectDocuments } from './ProjectDocuments';
import { ProjectInfo } from './ProjectInfo';
import { ProjectInteractions } from './ProjectInteractions';

export const Project = () => {
  const { isUserClient } = useAuthContext();
  const [interactionsPage, setInteractionsPage] = useState(1);

  const { data: dealData, loading } = useGetDealQuery();

  const { data: dealAssistantsData, loading: assistantsLoading } =
    useGetDealAssistantsQuery();

  const { data: dealDocumentsData, loading: dealDocumentsLoading } =
    useGetDealDocumentsQuery();

  const { data: dealInteractionsData, loading: interactionsLoading } =
    useGetDealInteractionsQuery({ interactionsPage });

  const dealInteractions = dealInteractionsData?.getDealInteractionLogByDealId;

  const dealAssistants =
    dealAssistantsData?.getDealById?.dealConsultantAssistants;

  const [AddDealInteraction] = useAddDealInteractionMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useAuthContext();

  const dealById = dealData?.getDealById;

  const dealDocuments = dealDocumentsData?.getDealById?.dealDocuments;

  const onAddDealInteractionSubmit = async (values) => {
    await AddDealInteraction({
      variables: {
        input: {
          dealId: +dealById?.id,
          text: values.text,
          type: values.type,
        },
      },
    });
  };

  return (
    <Box>
      <Typography mb={3} fontSize={18}>
        Dettaglio Praticca
      </Typography>
      <AddDealInteractionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={onAddDealInteractionSubmit}
      />
      <RequestHandler height='400px' loading={loading && interactionsPage < 2}>
        <Grid container spacing={2} height='90%'>
          <Grid item xs={8}>
            <Grid container spacing={2} direction='row'>
              <Grid item xs={12}>
                <Grid container spacing={2} direction='row'>
                  <Grid item xs={isUserClient ? 12 : 8}>
                    <ProjectInfo
                      gross={dealById?.gross}
                      net={dealById?.net}
                      name={dealById?.name}
                      status={dealById?.status}
                      createdDate={dealById?.createdAt}
                      approvedAt={dealById?.approvedAt}
                    />
                  </Grid>
                  {!isUserClient ? (
                    <Grid item xs={4}>
                      <ProjectAssistants
                        assistantsLoading={assistantsLoading}
                        assistants={dealAssistants}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ProjectChat user={user} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              <RequestHandler height='400px' loading={dealDocumentsLoading}>
                <ProjectDocuments dealDocuments={dealDocuments} />
              </RequestHandler>
              <ProjectInteractions
                interactionsLoading={interactionsLoading}
                interactionsPage={interactionsPage}
                setInteractionsPage={setInteractionsPage}
                setModalOpen={() => setIsModalOpen(true)}
                dealInteractionLogs={dealInteractions}
              />
            </div>
          </Grid>
        </Grid>
      </RequestHandler>
    </Box>
  );
};
