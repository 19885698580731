import { Box, Modal } from '@mui/material';
import React from 'react';

import { TYPE_MODAL } from '../../constants/constants';
import { useModalContext } from '../../contexts';
import { TestModal } from '../Modal';

export const ModalController = () => {
  const { modalParameters } = useModalContext();

  switch (modalParameters.type) {
    case TYPE_MODAL.TEST:
      return <TestModal />;

    default:
      return <div />;
  }
};

export const BasicModal = () => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();

  return (
    <Modal
      open={modalParameters?.isOpen}
      onClose={() => {
        handleChangeModalParameters([{ key: 'isOpen', value: false }]);
      }}
      sx={{
        maxHeight: '100vh',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          padding: '30px',
          outline: '0 !important',
        }}
      >
        <ModalController />
      </Box>
    </Modal>
  );
};
