import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useEditDealMutation } from '../../hooks';
import { editDealSchema } from '../../validation';

export const EditProjectModal = ({
  isOpen,
  onClose,
  status,
  gross = null,
  net = null,
}) => {
  const { projectId } = useParams();

  const [editDeal] = useEditDealMutation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(editDealSchema),
    defaultValues: {
      status: status,
      gross: gross,
      net: net,
    },
  });

  const handleOnSubmit = async (values) => {
    await editDeal({
      variables: {
        id: +projectId,
        input: {
          gross: values?.gross,
          net: values?.net,
          status: values?.status,
        },
      },
    });
    onClose();
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Modifica pratica</DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          <Stack spacing={2}>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-type-selector'>Stato</InputLabel>
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <Select error={Boolean(errors.status)} {...field}>
                    <MenuItem value='pending'>In attesa di</MenuItem>
                    <MenuItem value='pendingForPermission'>
                      In attesa di autorizzazione
                    </MenuItem>
                    <MenuItem value='bill'>Conto</MenuItem>
                    <MenuItem value='cachOut'>Incassare</MenuItem>
                  </Select>
                )}
              />
              {errors.status && (
                <FormHelperText error id='standard-weight-helper-text'>
                  {errors.status.message.toString()}
                </FormHelperText>
              )}
            </Stack>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-gross'>Schifoso</InputLabel>
              <OutlinedInput
                id='deal-gross'
                placeholder='Schifoso'
                fullWidth
                type='number'
                error={Boolean(errors.gross)}
                {...register('gross')}
              />
              {errors.gross && (
                <FormHelperText error id='standard-weight-helper-text'>
                  {errors.gross.message.toString()}
                </FormHelperText>
              )}
            </Stack>
            <Stack spacing={1}>
              <InputLabel htmlFor='deal-gross'>Rete</InputLabel>
              <OutlinedInput
                id='deal-net'
                placeholder='Rete'
                fullWidth
                type='number'
                error={Boolean(errors.net)}
                {...register('net')}
              />
              {errors.net && (
                <FormHelperText error id='standard-weight-helper-text'>
                  {errors.net.message.toString()}
                </FormHelperText>
              )}
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} type='submit'>
          Modifica
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditProjectModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  status: PropTypes.string,
  gross: PropTypes.number,
  net: PropTypes.number,
};
