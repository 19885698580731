import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAddDealConsultantAssistantMutation } from '../../hooks';
import { getFullName } from '../../utils';
import { addAssistantSchema } from '../../validation';

export const AddDealAssistant = ({
  isOpen,
  onClose,
  isAllConsultantsLoading,
  allConsultantsData,
  projectId,
}) => {
  const [AddDealAssistant] = useAddDealConsultantAssistantMutation();

  const consultantsArray =
    allConsultantsData?.getAllConsultantsExeptCurrentOneAsConsultant?.map(
      (consultant) => {
        return {
          id: consultant?.id,
          label: getFullName(consultant?.firstName, consultant?.lastName),
        };
      },
    ) || [];

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(addAssistantSchema),
    defaultValues: {
      consultant: null,
    },
  });

  const handleOnSubmit = async (values) => {
    await AddDealAssistant({
      variables: {
        input: {
          dealId: +projectId,
          consultantId: +values.consultant?.id,
        },
      },
    });
    onClose();
    reset();
  };

  return (
    <Dialog
      onSubmit={handleSubmit(handleOnSubmit)}
      component='form'
      fullWidth={true}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Aggiungi assistente</DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            m: 'auto',
            width: 'fit-content',
            gap: '20px',
          }}
        >
          <Stack spacing={2}>
            <Controller
              name='consultant'
              control={control}
              render={({ field: { onChange, ...restField } }) => (
                <Autocomplete
                  {...restField}
                  onChange={(event, value) => {
                    onChange({ target: { value } });
                  }}
                  loading={isAllConsultantsLoading}
                  isOptionEqualToValue={(option, value) => {
                    return +option.id === +value.id;
                  }}
                  options={consultantsArray}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(errors?.consultant)}
                      {...params}
                      placeholder='Scegliere...'
                    />
                  )}
                />
              )}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} type='submit'>
          Aggiungi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDealAssistant.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isAllConsultantsLoading: PropTypes.bool,
  allConsultantsData: PropTypes.object,
  projectId: PropTypes.string,
};
