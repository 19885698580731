import { Avatar, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { getFormattedDate } from '../../utils';
import {
  MainMessageWrapper,
  MessageTextWrapper,
  TypographyWrapper,
} from './styles';

// eslint-disable-next-line react/display-name
export const ChatMessage = React.memo(
  ({ user, messageText, date, isyou = false }) => {
    return (
      <MainMessageWrapper isyou={isyou}>
        <Avatar
          aria-label='recipe'
          sx={!isyou ? { marginRight: '20px' } : { marginLeft: '20px' }}
        >
          {user?.avatar || '?'}
        </Avatar>
        <Box display={'flex'} flexDirection={'column'}>
          <TypographyWrapper isyou={isyou}>
            {user?.firstName} {user?.lastName}
          </TypographyWrapper>
          <TypographyWrapper isyou={isyou}>
            {getFormattedDate(date)}
          </TypographyWrapper>
          <MessageTextWrapper isyou={isyou}>{messageText}</MessageTextWrapper>
        </Box>
      </MainMessageWrapper>
    );
  },
);

ChatMessage.propTypes = {
  user: PropTypes.object,
  messageText: PropTypes.string,
  messageStatus: PropTypes.number,
  date: PropTypes.string,
  isyou: PropTypes.boolean,
};
