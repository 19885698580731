import { Add, Delete } from '@mui/icons-material';
import {
  Card,
  CardContent,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AddDealAssistant, NoDataText, RequestHandler } from '../../components';
import { useAuthContext } from '../../contexts';
import {
  useGetAllConsultantsLazyQuery,
  useRemoveDealConsultantAssistantMutation,
} from '../../hooks';

export const ProjectAssistants = ({ assistants, assistantsLoading }) => {
  const { isUserConsultant } = useAuthContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { projectId } = useParams();

  const [
    getAllConsultants,
    { data: allConsultantsData, loading: isAllConsultantsLoading },
  ] = useGetAllConsultantsLazyQuery();

  const [removeAssistant] = useRemoveDealConsultantAssistantMutation();

  const handleRemoveAssistant = async (consultantId) => {
    await removeAssistant({
      variables: {
        input: {
          dealId: +projectId,
          consultantId: +consultantId,
        },
      },
    });
  };

  const openModalHandler = async () => {
    if (!allConsultantsData) {
      await getAllConsultants();
    }
    setIsModalOpen(true);
  };

  return (
    <Card sx={{ height: '100%', overflowY: 'auto' }}>
      <AddDealAssistant
        projectId={projectId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isAllConsultantsLoading={isAllConsultantsLoading}
        allConsultantsData={allConsultantsData}
      />
      <CardContent>
        <Stack
          justifyContent='flex-start'
          direction='row'
          gap={1}
          alignItems='center'
          mb={1}
        >
          <Typography variant='h7' component='div'>
            Assistenti
          </Typography>
          {isUserConsultant ? (
            <IconButton onClick={openModalHandler}>
              <Add />
            </IconButton>
          ) : null}
        </Stack>
        <RequestHandler loading={assistantsLoading}>
          {assistants?.length ? (
            assistants?.map((assistant) => (
              <ListItem
                secondaryAction={
                  isUserConsultant ? (
                    <IconButton
                      onClick={() =>
                        handleRemoveAssistant(assistant?.consultantId)
                      }
                      edge='end'
                      aria-label='delete'
                    >
                      <Delete />
                    </IconButton>
                  ) : null
                }
                sx={{ padding: '0 0 7px' }}
                key={assistant?.consultantId}
              >
                <ListItemText primary={assistant?.consultant?.firstName} />
              </ListItem>
            ))
          ) : (
            <NoDataText message='Niente assistenti' />
          )}
        </RequestHandler>
      </CardContent>
    </Card>
  );
};

ProjectAssistants.propTypes = {
  assistants: PropTypes.array,
  assistantsLoading: PropTypes.bool,
};
