import * as yup from 'yup';

import { messages as M } from '../messages';

export const editDealSchema = yup.object().shape({
  status: yup.string().nullable().required(M.REQUIRE_MESSAGE),
  gross: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
  net: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null)),
});
