import { Edit, MoreHoriz } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { API_DOMAIN, DEAL_REPORT_STATUS } from '../../constants/constants';
import { useIssueDealReport } from '../../hooks/useIssueDealReport';
import { useRequestDealReport } from '../../hooks/useRequestDealReport';
import { useUpdateDealReportStatus } from '../../hooks/useUpdateDealReportStatus';
import { palette } from '../../theme/foundations';
import { getColorStatusReports } from '../../utils';

export const DashboardReport = ({
  reports,
  isClient,
  isConsultant,
  isAdmin,
}) => {
  const [dealReportId, setDealReportId] = useState('');
  const [open, setOpen] = useState(false);

  const [
    setIsFile,
    handleIssueDealReport,
    issueDealReportAsConsultantLoading,
    reissueDealReportAsConsultantLoading,
    issueDealReportAsAdminLoading,
    reissueDealReportAsAdminLoading,
  ] = useIssueDealReport(dealReportId, isAdmin, isConsultant);

  const [
    newStatus,
    setNewStatus,
    handleUpdateStatusDealReport,
    updateDealReportStatusAsConsultantLoading,
    updateDealReportStatusAsAdminLoading,
  ] = useUpdateDealReportStatus(dealReportId, isAdmin, isConsultant);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Stack
          justifyContent='space-between'
          direction='row'
          alignItems='center'
        >
          <Typography fontSize={16}>Report Richiesti</Typography>
        </Stack>
        <TableContainer>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Report
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Date
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }} align='center'>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.map((report) => (
                <TableRow
                  key={report?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='center'>{report?.deal?.name}</TableCell>
                  <TableCell align='center'>
                    {format(
                      new Date(parseInt(report?.createdAt)),
                      'dd.MM.yyyy',
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    <Chip
                      sx={{
                        ...(isClient && {
                          cursor: 'auto',
                        }),
                      }}
                      onClick={() => {
                        if (!isClient) handleClickOpen();
                        setDealReportId(report.id);
                      }}
                      label={DEAL_REPORT_STATUS[report?.status]}
                      color={getColorStatusReports(report?.status)}
                    />
                  </TableCell>
                  <Dialog fullWidth={true} open={open} onClose={handleClose}>
                    <DialogTitle>Selezionare lo stato</DialogTitle>
                    <DialogContent sx={{ paddingTop: '24px !important' }}>
                      <Box
                        noValidate
                        component='form'
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          m: 'auto',
                          width: 'fit-content',
                        }}
                      >
                        <FormControl sx={{ width: '200px' }}>
                          <Typography sx={{ color: palette.text.typography }}>
                            Stato
                          </Typography>
                          <Select
                            id='select-new-status'
                            name='newStatus'
                            onChange={(e) => {
                              setNewStatus(e.target.value);
                            }}
                            value={newStatus}
                          >
                            {Object.entries(DEAL_REPORT_STATUS).map(
                              ([key, value]) => (
                                <MenuItem key={key} value={key}>
                                  {value}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => {
                          handleUpdateStatusDealReport();
                          setDealReportId(0);
                          handleClose();
                        }}
                      >
                        Modificato
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <TableCell align='center'>
                    {!isClient ? (
                      <>
                        <label htmlFor='file'>
                          <IconButton
                            color='inherit'
                            aria-label='upload-icon'
                            onClick={() => {
                              setDealReportId(report.id);
                              if (report.fileName) setIsFile(true);
                            }}
                            edge='start'
                            component='span'
                          >
                            <UploadFileIcon />
                          </IconButton>
                        </label>
                        <input
                          id='file'
                          style={{ display: 'none' }}
                          type='file'
                          accept='.pdf'
                          name={`file`}
                          onChange={async (e) => {
                            let file = await e.target.files[0];
                            handleIssueDealReport(file);
                          }}
                        />
                      </>
                    ) : report.fileName ? (
                      <a
                        href={`${API_DOMAIN}/uploads/deal-reports/${report.id}/${report.fileName}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'none' }}
                      >
                        <DownloadIcon />
                      </a>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

DashboardReport.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  isClient: PropTypes.boolean,
  isConsultant: PropTypes.boolean,
  isAdmin: PropTypes.boolean,
};
