import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { MainLayout, RequestHandler, TableSortCell } from '../../components';
import {
  API_DOMAIN,
  DEAL_REPORT_STATUS,
  DEAL_REPORT_TYPE,
  LIMIT_ITEM_PAGE,
} from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import {
  useFilter,
  useGetCompaniesAsAdminQuery,
  useGetCompaniesAsConsultantQuery,
  useGetDealReportsWithCountAsAdminLazyQuery,
  useGetDealReportsWithCountAsClientLazyQuery,
  useGetDealReportsWithCountAsConsultantLazyQuery,
  useGetDealsQuery,
} from '../../hooks';
import { useIssueDealReport } from '../../hooks/useIssueDealReport';
import { useRequestDealReport } from '../../hooks/useRequestDealReport';
import { useUpdateDealReportStatus } from '../../hooks/useUpdateDealReportStatus';
import { palette } from '../../theme/foundations';
import { getColorStatusReports } from '../../utils';

const headCells = [
  {
    label: 'Report',
  },
  {
    label: 'Stato',
  },
  {
    sortId: 'createdAt',
    label: 'Data Rechiesta',
  },
  {
    sortId: 'issuedAt',
    label: 'Data Report',
  },
  {
    label: 'Allegato',
  },
];

export const Report = () => {
  const { user } = useAuthContext();
  const { filters, setFilters } = useFilter();
  const [open, setOpen] = useState(false);
  const [dealReportId, setDealReportId] = useState(1);
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [pagePagination, setPagePagination] = useState(1);
  const [dealFilter, setDealFilter] = useState({
    dealId: '',
    companyId: '',
    status: '',
    type: '',
  });

  const isClient = user.role === 'client';
  const isConsultant = user.role === 'consultant';
  const isAdmin = user.role === 'admin';

  const input = {
    offset: (pagePagination - 1) * LIMIT_ITEM_PAGE,
    limit: LIMIT_ITEM_PAGE,
    sortingBy: getSortingByFieldEnum(filters.field, filters.order),
    ...(dealFilter.dealId && { dealId: dealFilter.dealId }),
    ...(dealFilter.companyId && { companyId: dealFilter.companyId }),
    ...(dealFilter.type && { type: dealFilter.type }),
    ...(dealFilter.status && { status: dealFilter.status }),
  };

  const [
    dealReportType,
    setDealReportType,
    requestDealReportAsClientLoading,
    handleRequestDealReportAsClient,
  ] = useRequestDealReport(input);

  const [
    setIsFile,
    handleIssueDealReport,
    issueDealReportAsConsultantLoading,
    reissueDealReportAsConsultantLoading,
    issueDealReportAsAdminLoading,
    reissueDealReportAsAdminLoading,
  ] = useIssueDealReport(dealReportId, isAdmin, isConsultant, input);

  const [
    newStatus,
    setNewStatus,
    handleUpdateStatusDealReport,
    updateDealReportStatusAsConsultantLoading,
    updateDealReportStatusAsAdminLoading,
  ] = useUpdateDealReportStatus(dealReportId, isAdmin, isConsultant);

  const { data: dealsData, loading: dealsLoading } = useGetDealsQuery({
    limit: 0,
    withSort: false,
  });

  const { data: companiesAsAdminData, loading: companiesAsAdminDataLoading } =
    useGetCompaniesAsAdminQuery({ isLimit: false });

  const {
    data: companiesAsConsultantData,
    loading: companiesAsConsultantLoading,
  } = useGetCompaniesAsConsultantQuery({ isLimit: false });

  const companies =
    companiesAsAdminData?.getCompaniesAsAdmin ||
    companiesAsConsultantData?.getCompaniesAsConsultant;

  const [
    getDealReportsAsClient,
    { data: dealReportsAsClientData, loading: dealReportsAsClientLoading },
  ] = useGetDealReportsWithCountAsClientLazyQuery({
    variables: {
      input,
    },
  });

  const [
    getDealReportsAsConsultant,
    {
      data: dealReportsAsConsultantData,
      loading: dealReportsAsConsultantLoading,
    },
  ] = useGetDealReportsWithCountAsConsultantLazyQuery({
    variables: {
      input,
    },
  });
  const [
    getDealReportsAsAdmin,
    { data: dealReportsAsAdminData, loading: dealReportsAsAdminLoading },
  ] = useGetDealReportsWithCountAsAdminLazyQuery({
    variables: {
      input,
    },
  });

  const dealReports =
    dealReportsAsClientData?.getDealReportsWithCountAsClient ||
    dealReportsAsConsultantData?.getDealReportsWithCountAsConsultant ||
    dealReportsAsAdminData?.getDealReportsWithCountAsAdmin;

  useEffect(() => {
    handleGetDealsReports();
  }, [dealFilter]);

  function getSortingByFieldEnum(sortBy, order) {
    switch (sortBy) {
      case 'createdAt':
        return order === 'asc' ? 'createdAtAsc' : 'createdAtDesc';
      case 'issuedAt':
        return order === 'asc' ? 'issuedAtAsc' : 'issuedAtDesc';
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenCreateRequest = () => {
    setOpenCreateRequest(true);
  };

  const handleCloseCreateRequest = () => {
    setOpenCreateRequest(false);
  };

  const handleGetDealsReports = () => {
    if (isClient) {
      return getDealReportsAsClient();
    } else if (isConsultant) {
      return getDealReportsAsConsultant();
    } else if (isAdmin) {
      return getDealReportsAsAdmin();
    }
  };

  const handleClearStatus = () => {
    setDealFilter({
      dealId: '',
      companyId: '',
      status: '',
      type: '',
    });
  };

  return (
    <RequestHandler
      loading={
        requestDealReportAsClientLoading ||
        dealsLoading ||
        dealReportsAsClientLoading ||
        dealReportsAsConsultantLoading ||
        dealReportsAsAdminLoading ||
        updateDealReportStatusAsConsultantLoading ||
        issueDealReportAsConsultantLoading ||
        reissueDealReportAsConsultantLoading ||
        reissueDealReportAsAdminLoading ||
        issueDealReportAsAdminLoading ||
        updateDealReportStatusAsAdminLoading ||
        companiesAsConsultantLoading ||
        companiesAsAdminDataLoading
      }
    >
      <MainLayout>
        <Typography mb={3} fontSize={18}>
          Report
        </Typography>
        <Grid mb={3} container spacing={3}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                ID del deal
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-deal-id'
                name='dealId'
                onChange={(e) => {
                  setDealFilter((prev) => ({
                    ...prev,
                    dealId: e.target.value,
                  }));
                }}
                value={dealFilter.dealId}
                defaultValue={dealFilter.dealId}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutte le offerte
                </MenuItem>
                {dealsData?.getDeals?.deals?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Stato
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-status'
                name='status'
                onChange={(e) => {
                  setDealFilter((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }));
                }}
                value={dealFilter.status}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutti gli stati
                </MenuItem>
                {Object.entries(DEAL_REPORT_STATUS).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Genere
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-type'
                name='type'
                onChange={(e) => {
                  setDealFilter((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }));
                }}
                value={dealFilter.type}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutti i tipi
                </MenuItem>
                {Object.entries(DEAL_REPORT_TYPE).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!isClient ? (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Typography sx={{ color: palette.text.typography }}>
                  Codice Identificativo dell&apos;azienda
                </Typography>
                <Select
                  displayEmpty
                  disableunderline='true'
                  id='select-company-id'
                  name='companyId'
                  onChange={(e) => {
                    setDealFilter((prev) => ({
                      ...prev,
                      companyId: e.target.value,
                    }));
                  }}
                  value={dealFilter.companyId}
                >
                  <MenuItem value='' sx={{ display: 'none' }}>
                    Tutte le aziende
                  </MenuItem>
                  {companies?.companies?.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={2}>
            <Button
              sx={{ marginTop: '30px' }}
              variant='contained'
              onClick={() => handleClearStatus()}
            >
              Annulla Filtri
            </Button>
          </Grid>
        </Grid>

        {isClient ? (
          <>
            <Grid mb={3} container spacing={3}>
              <Grid item xs={3}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDealReportType('deminimis');
                    handleClickOpenCreateRequest();
                  }}
                >
                  <CardContent>
                    <Typography align={'center'}>Deminimis</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDealReportType('cr');
                    handleClickOpenCreateRequest();
                  }}
                >
                  <CardContent>
                    <Typography align={'center'}>cr</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDealReportType('xRating');
                    handleClickOpenCreateRequest();
                  }}
                >
                  <CardContent>
                    <Typography align={'center'}>xRating</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDealReportType('mcc');
                    handleClickOpenCreateRequest();
                  }}
                >
                  <CardContent>
                    <Typography align={'center'}>mcc</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Dialog
              fullWidth={true}
              open={openCreateRequest}
              onClose={handleCloseCreateRequest}
            >
              <DialogTitle>
                Confermi la richiesta del Report ‘report name’ ?
              </DialogTitle>
              <DialogContent sx={{ paddingTop: '24px !important' }}>
                <Box
                  noValidate
                  component='form'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                ></Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleRequestDealReportAsClient(
                      dealReportType,
                      user.companyId,
                    );
                    setDealReportType('');
                    handleCloseCreateRequest();
                  }}
                >
                  Ok
                </Button>
                <Button
                  onClick={() => {
                    setDealReportType('');
                    handleCloseCreateRequest();
                  }}
                >
                  Annula
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
        <Card>
          <CardContent>
            <TableContainer>
              <Table aria-label='reports table'>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableSortCell
                        align='center'
                        SortCell
                        order={filters.order}
                        field={filters.field}
                        setFilters={setFilters}
                        key={headCell.label}
                        label={headCell.label}
                        sortId={headCell?.sortId}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealReports?.dealReports?.map((report) => (
                    <TableRow key={report.id}>
                      <TableCell align='center'>{report?.deal?.name}</TableCell>
                      <TableCell align='center'>
                        <Chip
                          sx={{
                            ...(isClient && {
                              cursor: 'auto',
                            }),
                          }}
                          onClick={() => {
                            if (!isClient) handleClickOpen();
                            setDealReportId(report.id);
                          }}
                          label={DEAL_REPORT_STATUS[report?.status]}
                          color={getColorStatusReports(report?.status)}
                        />
                      </TableCell>
                      <Dialog
                        fullWidth={true}
                        open={open}
                        onClose={handleClose}
                      >
                        <DialogTitle>Selezionare lo stato</DialogTitle>
                        <DialogContent sx={{ paddingTop: '24px !important' }}>
                          <Box
                            noValidate
                            component='form'
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              m: 'auto',
                              width: 'fit-content',
                            }}
                          >
                            <FormControl sx={{ width: '200px' }}>
                              <Typography
                                sx={{ color: palette.text.typography }}
                              >
                                Stato
                              </Typography>
                              <Select
                                id='select-new-status'
                                name='newStatus'
                                onChange={(e) => {
                                  setNewStatus(e.target.value);
                                }}
                                value={newStatus}
                              >
                                {Object.entries(DEAL_REPORT_STATUS).map(
                                  ([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                      {value}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              handleUpdateStatusDealReport();
                              setDealReportId(0);
                              handleClose();
                            }}
                          >
                            Modificato
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <TableCell align='center'>
                        {report?.createdAt
                          ? format(
                              new Date(parseInt(report?.createdAt)),
                              'dd.MM.yyyy',
                            )
                          : null}
                      </TableCell>
                      <TableCell align='center'>
                        {report?.issuedAt
                          ? format(
                              new Date(parseInt(report?.issuedAt)),
                              'dd.MM.yyyy',
                            )
                          : null}
                      </TableCell>
                      <TableCell align='center'>
                        {!isClient ? (
                          <>
                            <label htmlFor='file'>
                              <IconButton
                                color='inherit'
                                aria-label='upload-icon'
                                onClick={() => {
                                  setDealReportId(report.id);
                                  if (report.fileName) setIsFile(true);
                                }}
                                edge='start'
                                component='span'
                              >
                                <UploadFileIcon />
                              </IconButton>
                            </label>
                            <input
                              id='file'
                              style={{ display: 'none' }}
                              type='file'
                              accept='.pdf'
                              name={`file`}
                              onChange={async (e) => {
                                let file = await e.target.files[0];
                                handleIssueDealReport(file);
                                setDealReportId(0);
                              }}
                            />
                          </>
                        ) : report.fileName ? (
                          <a
                            href={`${API_DOMAIN}/uploads/deal-reports/${report.id}/${report.fileName}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ textDecoration: 'none' }}
                          >
                            <DownloadIcon />
                          </a>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={4}>
              <Pagination
                count={
                  dealReports?.count
                    ? Math.ceil(dealReports?.count / LIMIT_ITEM_PAGE)
                    : 1
                }
                page={pagePagination}
                onChange={(_, page) => {
                  setPagePagination(page);
                }}
                variant='outlined'
                color='primary'
              />
            </Box>
          </CardContent>
        </Card>
      </MainLayout>
    </RequestHandler>
  );
};
