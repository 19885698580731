import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AddDocumentModal,
  MainLayout,
  RequestHandler,
  TableSortCell,
} from '../../components';
import { EditDealDocumentDatesModal } from '../../components/EditDealDocumentDatesModal/EditDealDocumentDatesModal';
import { UpdateStatusInDealDocumentModal } from '../../components/UpdateStatusInDealDocumentModal/UpdateStatusInDealDocumentModal';
import { API_DOMAIN, DOCUMENT_STATUS, ROUTES } from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import {
  useEditDocumentDates,
  useGetAllDealDocumentByIdLazyQuery,
  useGetCompaniesAsAdminQuery,
  useGetCompaniesAsConsultantQuery,
  useGetDealsQuery,
  useUpdateStatusInDealDocumentMutation,
  useUploadDocumentFileMutation,
  useUploadDocumentLinkMutation,
} from '../../hooks';
import { useUploadDocumentFile } from '../../hooks/useUploadDocumentFile';
import { palette } from '../../theme/foundations';
import { getColorStatusDoc } from '../../utils';

export const Documents = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [companyId, setCompanyId] = useState('');
  const [dealId, setDealId] = useState('');
  const [isUpdateStatusDocumentModalOpen, setIsUpdateStatusDocumentModalOpen] =
    useState(false);

  const [dealDocumentId, setDealDocumentId] = useState(0);

  const [isEditDatesModalOpen, setIsEditDatesModalOpen] = useState(false);

  const isClient = user.role === 'client';
  const isAdmin = user.role === 'admin';
  const isConsultant = user.role === 'consultant';

  const { data: companiesAsAdminData, loading: companiesAsAdminDataLoading } =
    useGetCompaniesAsAdminQuery({
      isLimit: false,
      skip: !isAdmin,
      fetchPolicy: 'network-only',
    });

  const {
    data: companiesAsConsultantData,
    loading: companiesAsConsultantLoading,
  } = useGetCompaniesAsConsultantQuery({
    isLimit: false,
    skip: !isConsultant,
    fetchPolicy: 'network-only',
  });

  const { data: dealsData, loading: dealsLoading } = useGetDealsQuery({
    limit: 0,
    withSort: false,
    companyId,
    fetchPolicy: 'network-only',
  });

  const [getDeal, { data: dealData, loading: dealLoading }] =
    useGetAllDealDocumentByIdLazyQuery();

  const [
    onSubmit,
    uploadFileLoading,
    uploadLinkLoading,
    isModalOpen,
    setIsModalOpen,
  ] = useUploadDocumentFile(dealDocumentId);

  const [editDocumentDates, { loading: editDocumentDatesLoading }] =
    useEditDocumentDates();

  const headCells = [
    {
      label: 'Documento',
    },
    {
      label: 'Stato',
    },
    {
      label: 'Data Caricamento',
    },
    {
      label: 'Data Documento',
    },
    {
      label: 'Scadenza',
    },
    { label: '' },
    {
      ...(!isClient
        ? {}
        : {
            label: 'Dettaglio',
            align: 'center',
          }),
    },
  ];

  const companies =
    companiesAsAdminData?.getCompaniesAsAdmin ||
    companiesAsConsultantData?.getCompaniesAsConsultant;

  const deals = dealsData?.getDeals?.deals;

  useEffect(() => {
    if (!dealId) return;
    getDeal({ variables: { dealId } });
  }, [dealId]);

  const handleEditDocumentDates = ({ startedAt, endedAt }) => {
    editDocumentDates({
      variables: { dealDocumentId, input: { startedAt, endedAt } },
    });
  };

  const handleClickAddButton = () => {
    navigate(ROUTES.document);
  };
  const handleClickEditButton = (id) => {
    navigate(`${ROUTES.document}/${id}`);
  };

  return (
    <MainLayout>
      <Typography mb={3} fontSize={18}>
        Documenti
      </Typography>
      <Button
        sx={{ marginBottom: '24px' }}
        variant='contained'
        startIcon={<AddIcon />}
        onClick={handleClickAddButton}
      >
        Aggiungi documento
      </Button>
      {!isClient ? (
        <Grid mb={3} container spacing={3}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Codice Identificativo dell&apos;azienda
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-company-id'
                name='companyId'
                onChange={(e) => {
                  setCompanyId(e.target.value);
                }}
                value={companyId}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutte le aziende
                </MenuItem>
                {companies?.companies?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Scegli l&apos;affare
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-deal-id'
                name='dealId'
                onChange={(e) => {
                  setDealId(e.target.value);
                }}
                value={dealId}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutte le aziende
                </MenuItem>
                {deals?.map((deal) => {
                  return (
                    <MenuItem key={deal.id} value={deal.id}>
                      {deal.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ) : (
        <Grid mb={3} container spacing={3}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Typography sx={{ color: palette.text.typography }}>
                Seleziona la pratica
              </Typography>
              <Select
                displayEmpty
                disableunderline='true'
                id='select-deal-id'
                name='dealId'
                onChange={(e) => {
                  setDealId(e.target.value);
                }}
                value={dealId}
              >
                <MenuItem value='' sx={{ display: 'none' }}>
                  Tutte le aziende
                </MenuItem>
                {deals?.map((deal) => {
                  return (
                    <MenuItem key={deal.id} value={deal.id}>
                      {deal.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <RequestHandler
        loading={
          companiesAsAdminDataLoading ||
          companiesAsConsultantLoading ||
          dealsLoading ||
          dealLoading
        }
      >
        <Card>
          <CardContent>
            <AddDocumentModal
              onSubmit={onSubmit}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              loading={uploadFileLoading || uploadLinkLoading}
            />
            <EditDealDocumentDatesModal
              onSubmit={handleEditDocumentDates}
              isOpen={isEditDatesModalOpen}
              onClose={() => setIsEditDatesModalOpen(false)}
            />
            <UpdateStatusInDealDocumentModal
              open={isUpdateStatusDocumentModalOpen}
              onClose={() => setIsUpdateStatusDocumentModalOpen(false)}
              dealDocumentId={dealDocumentId}
              setDealDocumentId={setDealDocumentId}
            />
            <TableContainer>
              <Table aria-label='documents table'>
                <TableHead>
                  <TableRow>
                    {!isClient ? <TableCell></TableCell> : null}
                    {headCells.map((headCell) => (
                      <TableSortCell
                        align='center'
                        SortCell
                        key={headCell.label}
                        label={headCell.label}
                        sortId={headCell?.sortId}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealData?.getAllDealDocumentsByDealId?.map((document) => (
                    <TableRow key={document.id}>
                      {!isClient ? (
                        <TableCell>
                          {document?.fileName || document?.externalLink ? (
                            document?.fileName ? (
                              <a
                                href={`${API_DOMAIN}/uploads/deal-documents/${document.fileName}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <DownloadIcon />
                              </a>
                            ) : (
                              <a
                                href={document.externalLink}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{ textDecoration: 'none' }}
                              >
                                <DownloadIcon />
                              </a>
                            )
                          ) : null}
                        </TableCell>
                      ) : null}
                      <TableCell align='center'>
                        {document?.dealTypeDocument?.name || document?.name}
                      </TableCell>
                      <TableCell align='center'>
                        <Chip
                          sx={{
                            ...(isClient
                              ? {
                                  cursor: 'auto',
                                }
                              : null),
                          }}
                          onClick={() => {
                            if (!isClient)
                              setIsUpdateStatusDocumentModalOpen(true);
                            setDealDocumentId(document.id);
                          }}
                          label={DOCUMENT_STATUS[document?.status]}
                          color={getColorStatusDoc(document?.status)}
                        />
                      </TableCell>

                      <TableCell align='center'>
                        {format(
                          new Date(parseInt(document?.createdAt)),
                          'dd.MM.yyyy',
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {document.startedAt
                          ? format(
                              new Date(parseInt(document.startedAt)),
                              'dd.MM.yyyy',
                            )
                          : null}
                        {isClient && document.startedAt ? (
                          <IconButton
                            onClick={() => {
                              setIsEditDatesModalOpen(true);
                              setDealDocumentId(document.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                      <TableCell align='center'>
                        {document.endedAt
                          ? format(
                              new Date(parseInt(document.endedAt)),
                              'dd.MM.yyyy',
                            )
                          : null}
                        {isClient && document.endedAt ? (
                          <IconButton
                            onClick={() => {
                              setIsEditDatesModalOpen(true);
                              setDealDocumentId(document.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                      {isClient ? (
                        <TableCell align='center'>
                          <IconButton
                            color='inherit'
                            aria-label='upload-icon'
                            onClick={() => {
                              setIsModalOpen(true);
                              setDealDocumentId(document.id);
                            }}
                            edge='start'
                            component='span'
                          >
                            <UploadFileIcon />
                          </IconButton>
                        </TableCell>
                      ) : null}
                      <TableCell align='center'>
                        <IconButton
                          onClick={() => {
                            handleClickEditButton(document.id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </RequestHandler>
    </MainLayout>
  );
};
