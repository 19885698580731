import * as yup from 'yup';

import { messages as M } from '../messages';

export const addAssistantSchema = yup.object().shape({
  consultant: yup
    .object()
    .nullable()
    .transform((value) => (value ? value : null))
    .required(M.REQUIRE_MESSAGE),
});
