export const palette = {
  text: {
    primary: '#000000',
    secondary: '#FFFFFF',
    input: '#333333',
    typography: 'rgba(0, 0, 0, 0.6)',
  },
  primary: {
    main: '#4054b2',
  },
  blue400: '#457ca2',
  blue300: '#62beeb',
  background: '#f5f5f5',
};
