import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDate, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { MainLayout } from '../../components';
import { RequestHandler } from '../../components/RequestHandler/RequestHandler';
import { ROUTES } from '../../constants/constants';
import {
  useCreateDealDocumentMutation,
  useEditDealDocumentMutation,
  useGetDealsQuery,
  useGetOneDealDocumentByIdQuery,
} from '../../hooks';
import { palette } from '../../theme/foundations';
import { addDealDocumentValidationSchema } from '../../validation';

export const DocumentWrapper = () => {
  const { data: documentData, loading: documentLoading } =
    useGetOneDealDocumentByIdQuery();
  const { data: dealsData, loading: dealsLoading } = useGetDealsQuery({
    limit: 0,
    withSort: false,
  });

  return (
    <RequestHandler loading={documentLoading || dealsLoading}>
      <Document documentData={documentData} dealsData={dealsData} />
    </RequestHandler>
  );
};

const Document = ({ documentData, dealsData }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [createDealDocumentMutation, { loading: createDealDocumentLoading }] =
    useCreateDealDocumentMutation();

  const [editDealDocumentMutation, { loading: editDealDocumentLoading }] =
    useEditDealDocumentMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: yupResolver(addDealDocumentValidationSchema),
    defaultValues: {
      dealId: documentData?.getOneDealDocumentById?.dealId || '',
      name:
        documentData?.getOneDealDocumentById?.name ||
        documentData?.getOneDealDocumentById?.dealTypeDocument?.name ||
        '',
      endedAt:
        new Date(Number(documentData?.getOneDealDocumentById?.endedAt)) || '',
      startedAt:
        new Date(Number(documentData?.getOneDealDocumentById?.startedAt)) || '',
    },
  });

  const onSubmit = (input) => {
    createDealDocumentMutation({ variables: { input } });
    navigate(ROUTES.documents);
  };

  const onEdit = (input) => {
    editDealDocumentMutation({
      variables: { id: Number(id), input: input },
    });
    navigate(ROUTES.documents);
  };

  return (
    <RequestHandler
      loading={createDealDocumentLoading || editDealDocumentLoading}
    >
      <MainLayout>
        <Box
          component={'form'}
          onSubmit={handleSubmit(!id ? onSubmit : onEdit)}
          noValidate
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} item>
                  <Grid item xs={6}>
                    <InputLabel htmlFor='company-name'>Nome</InputLabel>
                    <OutlinedInput
                      id='company-name'
                      type='text'
                      placeholder="Nome dell 'azienda"
                      fullWidth
                      autoComplete='company-name'
                      error={Boolean(errors.name)}
                      {...register('name')}
                    />
                    {errors.name && (
                      <FormHelperText
                        error
                        id='standard-weight-helper-text-company-name'
                      >
                        {errors.name.message.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        Data documento
                      </Typography>
                      <Controller
                        name='startedAt'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              inputRef={ref}
                              error={Boolean(errors.startedAt)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors.startedAt && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-startedAt'
                        >
                          {errors.startedAt.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        Data scadenza
                      </Typography>
                      <Controller
                        name='endedAt'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              name={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              inputRef={ref}
                              error={Boolean(errors.endedAt)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors.endedAt && (
                        <FormHelperText
                          error
                          id='standard-weight-helper-text-endedAt'
                        >
                          {errors.endedAt.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography sx={{ color: palette.text.typography }}>
                        ID practica
                      </Typography>
                      <Controller
                        name='dealId'
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            id='select-deal-id'
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            inputRef={ref}
                            error={Boolean(errors.dealId)}
                          >
                            {dealsData.getDeals.deals.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.dealId && (
                        <FormHelperText error id='standard-helper-text-deal-id'>
                          {errors.dealId.message.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button
            disabled={isSubmitting}
            type='submit'
            sx={{ marginTop: '24px' }}
            variant='contained'
            startIcon={<AddIcon />}
          >
            {!documentData ? 'Aggiungi document' : 'Modificata document'}
          </Button>
        </Box>
      </MainLayout>
    </RequestHandler>
  );
};

Document.propTypes = {
  documentData: PropTypes.object,
  dealsData: PropTypes.object,
};
