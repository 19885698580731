import { AccountCircle } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { drawerWidth, ROUTES } from '../../constants/constants';
import { useAuthContext } from '../../contexts';
import { SearchBar } from '../SearchBar/SearchBar';
import { IconButtonsWrapper } from './styles';

export const Header = ({ handleDrawerOpen }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { signOut, isUserClient, user } = useAuthContext();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCurrentUser = () => {
    navigate(`${ROUTES.user}/${user.id}`);
  };

  const forgotPasswordHandle = async () => {
    await signOut();
    navigate(ROUTES.forgotPassword);
  };

  return (
    <AppBar position='fixed' style={{ height: 60 }}>
      <Toolbar>
        <Stack width={drawerWidth} alignItems='baseline' direction='row'>
          <ButtonBase disableRipple component={Link} to={ROUTES.home}>
            <img height='35px' src={'kps-logo.png'} alt='logo' />
          </ButtonBase>
        </Stack>
        <IconButtonsWrapper>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
          >
            <MenuIcon />
          </IconButton>
          {isUserClient ? (
            <Typography>
              {user?.firstName} {user?.lastName}
            </Typography>
          ) : null}
          <Stack direction='row' alignItems='center' spacing={3}>
            {ROUTES.isSearchScreen(pathname) ? <SearchBar /> : undefined}
            <div>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleMenu}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={signOut}>
                  <LogoutIcon sx={{ mr: 2 }} /> Disconnettersi
                </MenuItem>
                <MenuItem onClick={handleEditCurrentUser}>
                  <SettingsIcon sx={{ mr: 2 }} /> Il mio conto
                </MenuItem>
                <MenuItem onClick={forgotPasswordHandle}>
                  <LockIcon sx={{ mr: 2 }} />
                  Ha dimenticato la password
                </MenuItem>
              </Menu>
            </div>
          </Stack>
        </IconButtonsWrapper>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  handleDrawerOpen: PropTypes.func,
};
